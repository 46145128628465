import React from "react";

import { Box, Heading, List, ListItem, ListIcon } from "@chakra-ui/react";
import { BsCheck } from "react-icons/bs";

interface ListTableProps {
  title: string;
  items: Array<string>;
}

const ListTable: React.FC<ListTableProps> = ({ title, items }) => {
  const itemsRender = items.map((item, index) => {
    return (
      <ListItem key={index}>
        <ListIcon as={BsCheck} />
        {item}
      </ListItem>
    );
  });
  return (
    <Box padding="1rem" background="gray.100" borderRadius="4px">
      <Heading size="md" marginBottom="0.5rem">
        {title}
      </Heading>
      <List>{itemsRender}</List>
    </Box>
  );
};

export default ListTable;
