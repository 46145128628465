import * as React from "react";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useAxios, useSWR } from "moship";
import InsuredSettings from "./InsuredSettings";
import { InsuredSettingsType } from "./InsuredSettings";
import DeleteButton from "components/DeleteButton";
import DefaultSettings from "./DefaultSettings";
import AdditionalKiloSettings from "./AdditionalKiloSettings";

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select,
} from "@chakra-ui/react";

interface CreateEditRateProps {
  rateId: number | null;
  originId: number;
  onSuccess: (rateId: number | null) => void;
  openEditCities: () => void;
  openCityList: () => void;
}

type RateTypes = "default" | "insured" | "additional_kilo";

const CreateEditRate: React.FC<CreateEditRateProps> = ({
  rateId,
  originId,
  onSuccess,
  openEditCities,
  openCityList,
}) => {
  const [rateType, setRateType] = useState<RateTypes>("default");

  const axios = useAxios();
  const { t } = useTranslation();
  const { data: origin } = useSWR(`/api/v1/origin/${originId}/`);
  useEffect(() => {
    if (rateId !== 0) {
      axios
        .get(`/api/v1/origin/${originId}/rates/${rateId}/`)
        .then((response: any) => {
          setRateType(response.data.type);
        });
    }
    return () => {};
  }, [axios, originId, rateId]);

  return (
    <>
      <FormControl marginBottom={"1rem"}>
        <FormLabel>{t("rate-type-label", "Rate type")}</FormLabel>
        <Select
          value={rateType}
          onChange={(event) => {
            setRateType(event.target.value as RateTypes);
          }}
        >
          <option value="default">{t("default", "Default")}</option>
          <option value="additional_kilo">
            {t("additional-kilo", "Additional kilo")}
          </option>
          {origin?.allow_insured_rate_type && (
            <option value="insured">{t("Insured")}</option>
          )}
        </Select>
      </FormControl>

      {rateType === "default" && (
        <DefaultSettings
          originId={originId}
          rateId={rateId}
          onSuccess={onSuccess}
        />
      )}
      {rateType === "additional_kilo" && (
        <AdditionalKiloSettings
          originId={originId}
          rateId={rateId}
          onSuccess={onSuccess}
        />
      )}
      {rateType === "insured" && (
        <InsuredSettings
          originId={originId}
          rateId={rateId}
          onSuccess={onSuccess}
        />
      )}

      <Box width={"100%"} py={"1rem"}>
        <Divider />
      </Box>

      <Flex justifyContent={"flex-end"} width={"100%"} paddingY={"1rem"}>
        <HStack>
          <Button
            onClick={() => {
              openEditCities();
            }}
          >
            {t("Add cities")}
          </Button>
          <Button colorScheme={"teal"} type="submit" form="save-rate">
            {t("Save rate")}
          </Button>
        </HStack>
      </Flex>
      <Flex justifyContent={"flex-end"}>
        <Button variant={"link"} onClick={openCityList}>
          {t("Active cities")}
        </Button>
      </Flex>

      {rateId !== 0 && (
        <>
          <Box marginTop="1rem" marginBottom={"1.6rem"}>
            <Divider />
          </Box>
          <Flex justifyContent="flex-end">
            <DeleteButton
              buttonText={t("Delete rate")}
              alertTitle={t("Delete rate")}
              alertDescription={t("Are you sure you want to delete this rate?")}
              cancelButtonText={t("Cancel")}
              confirmButtonText={t("Delete")}
              onDelete={() => {
                axios
                  .delete(`/api/v1/origin/${originId}/rates/${rateId}/`)
                  .then(() => {
                    onSuccess(null);
                  });
              }}
            />
          </Flex>
        </>
      )}
    </>
  );
};

export default CreateEditRate;
