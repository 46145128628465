import * as React from "react";
import { useState } from "react";
import { useSWR } from "moship";
import { useParams } from "react-router-dom";

import EmptyState from "components/EmptyState";

import { useTranslation } from "react-i18next";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Text,
  Flex,
  Heading,
  Button,
  Divider,
} from "@chakra-ui/react";

import CreateEditRate from "./CreateEditRate";
import RateCities from "./RateCities";
import CityList from "./CityList";

interface RatesProps {}

const Rates: React.FC<RatesProps> = () => {
  const { originId } = useParams<{ originId: string }>();
  const [openedRate, setOpenedRate] = useState<null | number>(null);

  const [openedPanel, setOpenedPanel] = useState<
    "rate" | "cityEditor" | "cityList"
  >("rate");

  const { t } = useTranslation();

  const {
    data: rates,
    error: ratesError,
    mutate: ratesMutate,
  } = useSWR(`/api/v1/origin/${originId}/rates/`);
  if (!rates) return null;

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Heading size={"md"}>{t("Shipping rates")}</Heading>
        <Button
          colorScheme={"teal"}
          onClick={() => {
            setOpenedRate(0);
          }}
        >
          {t("Create rate")}
        </Button>
      </Flex>
      <Divider marginY={"1rem"} />
      {rates.length === 0 && (
        <EmptyState
          title={t("You dont have any rate yet")}
          description={t(
            "After you create your first rate it will apperar here"
          )}
          primaryActionText={t("Create rate")}
          onPrimaryAction={() => {
            setOpenedRate(0);
          }}
        />
      )}
      {rates.length > 0 && !ratesError && (
        <Box>
          <Table>
            <Thead>
              <Tr fontWeight={"500"}>
                <Td>{t("Rate name")}</Td>
                <Td>{t("Price")}</Td>
              </Tr>
            </Thead>
            <Tbody>
              {rates.map((rate: any) => {
                return (
                  <Tr
                    key={rate.id}
                    cursor={"pointer"}
                    sx={{ "&:hover": { background: "#e7e7e7" } }}
                    onClick={() => {
                      setOpenedRate(rate.id);
                    }}
                  >
                    <Td>
                      <Text fontWeight={"500"} marginBottom="0.2rem">
                        {rate.internal_name ? rate.internal_name : rate.name}
                      </Text>
                      <Text fontSize="sm">{rate.name}</Text>
                      <Text fontSize="sm">{rate.description}</Text>
                    </Td>
                    <Td>
                      {rate.price_is_calculated
                        ? t("calculated", "Calculated")
                        : rate.price}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
      <Drawer
        isOpen={openedRate !== null}
        onClose={() => {
          setOpenedRate(null);
          setOpenedPanel("rate");
        }}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <>
              {openedRate === 0 && t("Create rate")}
              {openedRate !== 0 && t("Edit rate")}
            </>
          </DrawerHeader>
          <DrawerBody>
            {openedPanel === "rate" && (
              <CreateEditRate
                rateId={openedRate}
                originId={parseInt(originId)}
                onSuccess={(rateId) => {
                  setOpenedRate(rateId);
                  ratesMutate();
                }}
                openEditCities={() => {
                  setOpenedPanel("cityEditor");
                }}
                openCityList={() => {
                  setOpenedPanel("cityList");
                }}
              />
            )}
            {openedPanel === "cityEditor" && (
              <RateCities
                rateId={openedRate || 0}
                originId={originId}
                onBack={() => {
                  setOpenedPanel("rate");
                }}
              />
            )}
            {openedPanel === "cityList" && (
              <CityList
                rateId={openedRate || 0}
                originId={originId}
                onBack={() => {
                  setOpenedPanel("rate");
                }}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Rates;
