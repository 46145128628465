export const getFulfillmentOrderDestination = (data: any) => {
  let destination = {
    city: null,
    state: null,
  };

  if (data.shipping_address) {
    destination.city = data.shipping_address.city;
    destination.state = data.shipping_address.province;
  }

  return destination;
};

export const getFulfillmentOrderLineItems = (data: any) => {
  const lineItemsArray = data.line_items.map((lineItem, index) => {
    return {
      imageSrc: lineItem.scaled_img,
      title: lineItem.name,
      sku: lineItem.sku,
      totalQuantity: lineItem.total_quantity,
      packedQuantity: lineItem.remaining_quantity,
      remainingQuantity: lineItem.remaining_quantity,
      productShopifyRid: lineItem.product_rid,
      variantShopifyRid: lineItem.variant_rid,
    };
  });
  return lineItemsArray;
};
