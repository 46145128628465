import React, { useEffect, useState, useMemo } from "react";

import axios from "axios";

import { Select, Skeleton } from "@chakra-ui/react";

interface DaneCodePickerProps {
  destination: {
    city: string;
    state: string;
  };
  onChange: (selectedDanecode: string) => void;
}

const DaneCodePicker: React.FC<DaneCodePickerProps> = ({
  destination,
  onChange,
}) => {
  const [places, setPlaces] = useState([]);
  const [selectedDaneCode, setSelectedDaneCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getDanecodeInfo = async (city, state) => {
    const response = await axios
      .post(`https://danecode.service.moship.io/`, {
        city: city,
        state: state,
        include_city_list: true,
      })
      .then((response) => {
        return response;
      });
    return response;
  };

  const danecodeData = useMemo(async () => {
    let data = { cityCode: "", municipalities: [] };
    let response = await getDanecodeInfo(destination.city, destination.state);

    Object.keys(response.data.cities).map((state) => {
      Object.keys(response.data.cities[state]).map((city) => {
        data.municipalities.push(response.data.cities[state][city]);
        return null;
      });
      return null;
    });

    data.cityCode = response.data.city_code;

    return data;
  }, [destination.city, destination.state]);

  useEffect(() => {
    danecodeData.then((danecodeData) => {
      setSelectedDaneCode(danecodeData.cityCode);
      setPlaces(danecodeData.municipalities);
      setIsLoading(false);
    });
    return () => {};
  }, [danecodeData]);

  useEffect(() => {
    onChange(selectedDaneCode);
    return () => {};
  }, [selectedDaneCode, onChange]);

  const placesOptions = places.map((place, index) => {
    const cityCode =
      place.city_code.length === 4 ? `0${place.city_code}` : place.city_code;
    return (
      <option value={cityCode} key={index}>
        {place.city} - {place.state} - {cityCode}
      </option>
    );
  });

  if (isLoading) return <Skeleton height="2.5rem" borderRadius="4px" />;

  return (
    <Select
      value={selectedDaneCode}
      onChange={(e) => {
        setSelectedDaneCode(e.target.value);
      }}
    >
      {placesOptions}
    </Select>
  );
};

export default DaneCodePicker;
