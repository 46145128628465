import React from "react";

import {
  Flex,
  Image,
  VStack,
  Text,
  Box,
  Link,
  Icon,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Heading,
} from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

import { useTranslation } from "react-i18next";

import DefaultImage from "./images/no-image.jpg";

export interface LineItemProps {
  imageSrc: string;
  title: string;
  sku: string;
  packedQuantity: number;
  remainingQuantity: number;
  totalQuantity: number;
  productShopifyRid: string;
  variantShopifyRid: string;
}

export interface LineItemsProps {
  items: Array<LineItemProps>;
  shopSubdomain: string;
  fulfilled?: boolean;
}

const LineItems: React.FC<LineItemsProps> = ({
  items,
  shopSubdomain,
  fulfilled,
}) => {
  const { t } = useTranslation();

  const itemsRender = items.map((lineItem, index) => {
    const imageSrc = lineItem.imageSrc ? lineItem.imageSrc : DefaultImage;

    let lineItemTitle = <Text lineHeight="1.2rem">{lineItem.title}</Text>;

    if (lineItem.productShopifyRid && lineItem.variantShopifyRid) {
      const productUrl = `https://${shopSubdomain}.myshopify.com/admin/products/${lineItem.productShopifyRid}/variants/${lineItem.variantShopifyRid}`;
      lineItemTitle = (
        <Link
          lineHeight="1.2rem"
          as="a"
          color="blue.600"
          href={productUrl}
          target="_blank"
          isExternal
        >
          {lineItem.title}
          <Icon
            marginLeft="0.4rem"
            marginBottom="0.2rem"
            as={FaExternalLinkAlt}
            boxSize="0.75rem"
          />
        </Link>
      );
    }

    const showQtyform = false;

    return (
      <Flex key={index} width="100%">
        <Image
          src={imageSrc}
          maxW="60px"
          borderRadius={4}
          marginRight="0.5rem"
        />
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <VStack spacing={0} align="flex-start">
            {lineItemTitle}
            <Flex>
              <Text lineHeight="1rem" marginRight="0.25rem" fontWeight="500">
                SKU:
              </Text>
              <Text lineHeight="1rem">{lineItem.sku}</Text>
            </Flex>
          </VStack>

          <Box position="relative">
            <Text
              size="xl"
              fontWeight="bold"
              width="max-content"
              marginLeft="5px"
            >
              x {lineItem.totalQuantity}
            </Text>
            {showQtyform && (
              <>
                <NumberInput
                  size="sm"
                  defaultValue={lineItem.packedQuantity}
                  value={lineItem.packedQuantity}
                  min={0}
                  max={lineItem.totalQuantity}
                  maxW="120px"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Box
                  position="absolute"
                  top="4px"
                  right="30px"
                  color="blackAlpha.600"
                >
                  of {lineItem.remainingQuantity}
                </Box>
              </>
            )}
          </Box>
        </Flex>
      </Flex>
    );
  });

  let title = t([
    "containers.create-shipment.line-items.title-unfulfilled",
    "Items to fulfill",
  ]);
  if (fulfilled) {
    title = "Fulfilled items";
    title = t([
      "containers.create-shipment.line-items.title-fulfilled",
      "Fulfilled items",
    ]);
  }
  return (
    <>
      <Heading size="md" marginBottom="0.7rem">
        {title}
      </Heading>
      <VStack spacing={2} alignItems="flex-start">
        {itemsRender}
      </VStack>
    </>
  );
};

export default LineItems;
