import React from "react";
import { useParams } from "react-router-dom";

import CreateShipment from "containers/CreateShipment";
import { Container, Flex, Box } from "@chakra-ui/react";

interface FulfillmentOrderProps {}

const FulfillmentOrder: React.FC<FulfillmentOrderProps> = () => {
  const { fulfillmentOrderId } = useParams<{ fulfillmentOrderId: string }>();

  return (
    <Container maxWidth="container.xl" paddingTop={5}>
      <Flex alignItems="center" minH="90vh">
        <Box shadow="md" padding={5}>
          <CreateShipment
            fulfillmentOrderId={fulfillmentOrderId}
            onFulfillmentCreated={() => {}}
            onFulfillmentCancelled={() => {}}
          />
        </Box>
      </Flex>
    </Container>
  );
};

export default FulfillmentOrder;
