import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

export interface ChildItemProps {
  label: string;
  path: string;
}

export interface MainMenuItemProps {
  label: string;
  path?: string;
  icon?: any;
  uri?: string;
  target?: string;
}

interface MenuItemProps {
  activePath: string;
  onItemClick: (path: string) => void;
  mainItem: MainMenuItemProps;
  childItems: Array<ChildItemProps>;
}

const MenuItem: React.FC<MenuItemProps> = ({
  activePath,
  onItemClick,
  mainItem,
  childItems,
}) => {
  const isActive = activePath.includes(mainItem.path);
  const showChilds = childItems && isActive ? true : false;
  let classes = "item";

  if (isActive) {
    classes += " is-active";
  }

  childItems = childItems ? childItems : [];
  const renderChildrenItems = childItems.map((item, index) => {
    let classes = "item";
    const itemPath = item.path.split("?")[0];

    const isActive = activePath.includes(itemPath);
    if (isActive) {
      classes += " is-child-active";
    }

    return (
      <Box
        padding="4px 10px 4px 36px"
        marginBottom="4px"
        cursor="pointer"
        onClick={() => {
          onItemClick(item.path);
        }}
        key={index}
        className={`${classes}`}
      >
        {item.label}
      </Box>
    );
  });

  return (
    <Box
      marginBottom="4px"
      sx={{
        ".is-active": {
          background: "#f0f0f0",
        },
        ".is-child-active": {
          background: "#f0f0f0",
        },
        ".item:hover": {
          background: "#f0f0f0",
        },
      }}
    >
      <Flex
        padding="4px 10px 4px 5px"
        cursor="pointer"
        alignItems="center"
        className={`${classes}`}
        onClick={() => {
          if (mainItem.uri) {
            window.open(mainItem.uri, "_blank");
          } else {
            onItemClick(mainItem.path);
          }
        }}
        sx={{
          "&:hover": {
            background: "#FEFCBF",
          },
        }}
      >
        <Box marginRight="10px">{mainItem.icon}</Box>
        <Text
          textStyle="capitalize"
          fontSize="1rem"
          lineHeight="1rem"
          fontWeight="500"
        >
          {mainItem.label}
        </Text>
      </Flex>
      {showChilds && <Box marginTop="4px">{renderChildrenItems}</Box>}
    </Box>
  );
};

export default MenuItem;
