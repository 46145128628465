import React, { useState, useEffect } from "react";

import { useSWR, useAxios, usePushPath } from "moship";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
  Divider,
  Select,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import FormGroup from "components/FormGroup";
import SaveChanges from "components/SaveChanges";
import ListTable from "components/ListTable";
import CarrierLocations from "containers/CarrierLocations";

const Servientrega: React.FC<{ carrierId: string | number }> = ({
  carrierId,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();
  const {
    data: carrier,
    isLoading,
    error,
  } = useSWR(`/api/v1/carriers/${carrierId}/`);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeLocations, setActiveLocations] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const pushPath = usePushPath();

  const [values, setValues] = useState({
    cod_id_codigo_facturacion: "",
    cod_login: "",
    cod_password: "",
    company_name: "",
    default_declared_value: "",
    id_codigo_facturacion: "",
    login: "",
    nit: "",
    package_content: "",
    password: "",
    phone: "",
    use_default_declared_value: "true",
  });

  useEffect(() => {
    if (!error && !isLoading) {
      setName(carrier.name);
      setValues(carrier.settings);
      const locations = carrier.active_locations.map((location) => {
        return location.id;
      });
      setActiveLocations(locations);
    }

    return () => {};
  }, [carrier, error, isLoading]);

  if (isLoading) return null;
  if (error) return null;

  const onChange = (name) => (event) => {
    setFormChanged(true);
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const onsubmit = () => {
    setLoading(true);
    axios
      .put(`/api/v1/carriers/${carrierId}/`, {
        name: name,
        active_locations: activeLocations,
        settings: values,
      })
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
          setFormChanged(false);
          toast({
            title: t("new settings saved"),
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    axios.delete(`/api/v1/carriers/${carrierId}`).then((response) => {
      pushPath("/carriers");
    });
  };

  const onCarrierLocationsChange = (locations) => {
    setFormChanged(true);
    setActiveLocations(locations);
  };

  const items = [
    t("pages.carrier.servientrega.requirement-origin", "Origin: Colombia"),
    t(
      "pages.carrier.servientrega.requirement-destination",
      "Destination: Colombia"
    ),
    t(
      "pages.carrier.servientrega.requirement-cop-currency",
      "If the order is COD currency must be COP"
    ),
    t(
      "pages.carrier.servientrega.requirement-currency",
      'If the order is not COD currency must be COP or "use default declared value" settings must be set to "YES"'
    ),
  ];

  return (
    <>
      <Box position="relative" mb="7rem">
        <Box marginBottom="2rem">
          <ListTable
            title={t(
              "pages.carrier.servientrega.requirements-title",
              "Order requirements for this carrier"
            )}
            items={items}
          />
        </Box>
        <Box width="100%" padding="0 1rem">
          <FormGroup
            title={t("pages.carrier.location", "Location")}
            description={t("pages.carrier.location-description", "")}
          >
            <CarrierLocations
              onChange={onCarrierLocationsChange}
              activeLocations={activeLocations}
            />
          </FormGroup>
          <Divider my="2rem" />
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onsubmit();
            }}
          >
            <FormControl marginBottom="1rem">
              <FormLabel>
                {t("pages.carrier.servientrega.custom-name", "Custom name")}
              </FormLabel>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setFormChanged(true);
                }}
                required
              />
            </FormControl>
            <FormGroup
              title={t([
                "pages.carrier.servientrega.connection",
                "Connection settings",
              ])}
              description={t(
                ["pages.carrier.servientrega.connection-description"],
                ""
              )}
            >
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel>
                    {t("pages.carrier.servientrega.username", "Username")}
                  </FormLabel>
                  <Input
                    onChange={onChange("login")}
                    value={values.login}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("pages.carrier.servientrega.password", "Password")}
                  </FormLabel>
                  <Input
                    type="password"
                    onChange={onChange("password")}
                    value={values.password}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t(
                      "pages.carrier.servientrega.id-invoicing-code",
                      "Id invoicing code"
                    )}
                  </FormLabel>

                  <Input
                    onChange={onChange("id_codigo_facturacion")}
                    value={values.id_codigo_facturacion}
                    required
                  />
                </FormControl>
              </Stack>
            </FormGroup>
            <Divider my="2rem" />
            <FormGroup
              title={t(
                "pages.carrier.servientrega.cod-connection",
                "COD Conection settings"
              )}
              description={t(
                "pages.carrier.servientrega.cod-connection-description",
                ""
              )}
            >
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel>
                    {t("pages.carrier.servientrega.username", "Username")}
                  </FormLabel>
                  <Input
                    onChange={onChange("cod_login")}
                    value={values.cod_login}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("pages.carrier.servientrega.password", "Password")}
                  </FormLabel>
                  <Input
                    type="password"
                    onChange={onChange("cod_password")}
                    value={values.cod_password}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t(
                      "pages.carrier.servientrega.id-invoicing-code",
                      "Id invoicing code"
                    )}
                  </FormLabel>

                  <Input
                    onChange={onChange("cod_id_codigo_facturacion")}
                    value={values.cod_id_codigo_facturacion}
                    required
                  />
                </FormControl>
              </Stack>
            </FormGroup>
            <Divider my="2rem" />
            <FormGroup
              title={t(
                "pages.carrier.servientrega.company-settings",
                "Company settings"
              )}
              description={t(
                "pages.carrier.servientrega.company-settings-description",
                ""
              )}
            >
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel>
                    {t(
                      "pages.carrier.servientrega.company-name",
                      "Company name"
                    )}
                  </FormLabel>
                  <Input
                    onChange={onChange("company_name")}
                    value={values.company_name}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("pages.carrier.servientrega.nit", "Nit")}
                  </FormLabel>
                  <Input
                    onChange={onChange("nit")}
                    value={values.nit}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("pages.carrier.servientrega.phone", "Phone")}
                  </FormLabel>
                  <Input
                    onChange={onChange("phone")}
                    value={values.phone}
                    required
                  />
                </FormControl>
              </Stack>
            </FormGroup>
            <Divider my="2rem" />
            <FormGroup
              title={t(
                "pages.carrier.servientrega.shipment-settings",
                "Shipment settings"
              )}
              description={t(
                "pages.carrier.servientrega.shipment-settings-description",
                ""
              )}
            >
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel>
                    {t(
                      "pages.carrier.servientrega.use-declared-value",
                      "Use default declared value"
                    )}
                  </FormLabel>
                  <Select
                    value={values.use_default_declared_value}
                    onChange={onChange("use_default_declared_value")}
                    required
                  >
                    <option value=""></option>
                    <option value="true">{t("Yes")}</option>
                    <option value="false">{t("No")}</option>
                  </Select>
                  <FormHelperText>
                    {t(
                      "pages.carrier.servientrega.use-declared-value-helper",
                      "If 'No' the shipment will be generated with the order value"
                    )}
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t(
                      "pages.carrier.servientrega.default-declared-value",
                      "Default declared value"
                    )}
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon>$COP</InputLeftAddon>
                    <Input
                      type="number"
                      value={values.default_declared_value}
                      onChange={onChange("default_declared_value")}
                      required
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t(
                      "pages.carrier.servientrega.package-content",
                      "Package content"
                    )}
                  </FormLabel>
                  <Input
                    type="text"
                    value={values.package_content}
                    onChange={onChange("package_content")}
                    required
                  />
                </FormControl>
              </Stack>
            </FormGroup>
            <SaveChanges
              isLoading={loading}
              formChanged={formChanged}
              onDelete={onDelete}
              deleteAlertTitle={t("Delete carrier")}
              deleteAlertDescription={t(
                "Are you sure? You can't undo this action afterwards."
              )}
            />
          </form>
        </Box>
      </Box>
    </>
  );
};

export default Servientrega;
