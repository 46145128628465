import * as React from "react";
import { useState, useEffect } from "react";

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import Dashboard from "containers/Dashboard";

import { useTranslation } from "react-i18next";
import MoshipContainer from "components/Container";

import AssignValues from "./AsignValues";
import ValuesEditor from "./ValuesEditor";

interface CarriersLocationsProps {}

const CarriersLocations: React.FC<CarriersLocationsProps> = ({}) => {
  const { t } = useTranslation();

  return (
    <Dashboard>
      <MoshipContainer title="Asigna transportadoras a destinos">
        <Tabs>
          <TabList>
            <Tab>{t("Valores actuales")}</Tab>
            <Tab>{t("Asignar valores")}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ValuesEditor />
            </TabPanel>
            <TabPanel>
              <AssignValues />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box></Box>
      </MoshipContainer>
    </Dashboard>
  );
};

export default CarriersLocations;
