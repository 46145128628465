import React, { useState } from "react";

import {
  Box,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Container from "components/Container";
import EmptyState from "components/EmptyState";
import Dashboard from "containers/Dashboard";

import CountryCarriers from "containers/CountryCarriers";
import CarriersTable from "./CarriersTable";

import Flag from "react-world-flags";

import { useSWR, usePushPath } from "moship";

const Carriers: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: carriers, isLoading, error } = useSWR("/api/v1/carriers");
  const pushPath = usePushPath();

  if (isLoading) return null;
  if (error) return null;

  const carriersRender = carriers.map((carrier, index) => {
    return <Box key={index}>{carrier.provider}</Box>;
  });

  const carriersData = carriers.map((carrier) => {
    const locations = carrier.active_locations.map((carrier) => {
      return carrier.name;
    });
    return {
      id: carrier.id,
      name: carrier.name,
      provider: carrier.get_provider_display,
      activeLocations: locations,
    };
  });

  return (
    <Dashboard>
      <Container
        title={t("pages.carriers.title")}
        description={t("pages.carriers.description")}
        action={{
          text: t("pages.carriers.add-carrier-button"),
          onClick: () => {
            setIsModalOpen(true);
          },
        }}
      >
        {carriersRender.length === 0 && (
          <EmptyState
            title={t("pages.carriers.empty-title")}
            description={t("pages.carriers.empty-description")}
            primaryActionText={t("pages.carriers.empty-add-button")}
            onPrimaryAction={() => {
              setIsModalOpen(true);
            }}
          />
        )}
        <CarriersTable
          carriers={carriersData}
          onCarrierClick={(carrierId) => {
            pushPath(`/carriers/${carrierId}`);
          }}
        />
        <Box paddingX="1.2rem">
          <Modal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t("pages.carriers.add-carrier-modal-title")}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box background="#e2e8f0" borderRadius="4px">
                  <Box display="flex" alignItems="center">
                    <Box paddingLeft="0.8rem" marginRight="0.6rem">
                      <Flag code="CO" height="35" width="34" />
                    </Box>
                    <Select>
                      <option value="CO">{t("Colombia")}</option>
                    </Select>
                  </Box>
                </Box>
                <Divider my={5} />
                <Box marginBottom="2rem">
                  <CountryCarriers country="CO" />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Container>
    </Dashboard>
  );
};

export default Carriers;
