import React from "react";
import { useSWR } from "moship";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";

interface CarrierLocationProps {
  onChange: (locations: Array<number>) => void;
  activeLocations: Array<number>;
}

const CarrierLocations: React.FC<CarrierLocationProps> = ({
  onChange,
  activeLocations,
}) => {
  const { data: locations, isLoading, error } = useSWR("/api/v1/locations");
  if (isLoading) return null;
  if (error) return null;

  const locationsRender = locations.map((location: any, index: number) => {
    const isChecked = activeLocations.includes(location.id) ? true : false;
    return (
      <FormControl display="flex" alignItems="center" key={index}>
        <Switch
          marginRight="1rem"
          value={location.id}
          isChecked={isChecked}
          onChange={(e) => {
            let newActiveLocations = [...activeLocations];
            const locationId = parseInt(e.target.value);
            if (e.target.checked) {
              newActiveLocations.push(locationId);
            } else {
              var index = newActiveLocations.indexOf(locationId);
              newActiveLocations.splice(index, 1);
            }
            onChange(newActiveLocations);
          }}
        />
        <FormLabel marginTop="0.4rem">{location.name}</FormLabel>
      </FormControl>
    );
  });

  return <>{locationsRender}</>;
};

export default CarrierLocations;
