import React, { useState, useEffect } from "react";

import { usePushPath } from "moship";

import OrderInfo from "./OrderInfo";

import { useTranslation } from "react-i18next";

import AvailableCarriers from "./AvailableCarriers";
import CarrierRenderer from "./CarrierRenderer";
import TopOrderInfo from "./TopOrderInfo";
import LineItems from "./LineItems";
import Packages from "./Packages";
import Error from "./Error";
import Fulfillment from "./Fulfillment";

import { usePostHog } from "posthog-js/react";

import {
  getFulfillmentOrderDestination,
  getFulfillmentOrderLineItems,
} from "./utils";

import { Box, Button, Checkbox, Flex, Heading, Text } from "@chakra-ui/react";

import { useSWR, useAxios } from "moship";
import LoadingState from "./LoadingState";

interface CreateShipmentProps {
  fulfillmentOrderId: number | string;
  onFulfillmentCreated: () => void;
  onFulfillmentCancelled: () => void;
}

const CreateShipment: React.FC<CreateShipmentProps> = ({
  fulfillmentOrderId,
  onFulfillmentCreated,
  onFulfillmentCancelled,
}) => {
  const posthog = usePostHog();
  const pushPath = usePushPath();
  const { t } = useTranslation();
  const axios = useAxios();
  const {
    data: fulfillmentOrder,
    isLoading,
    isValidating,
    error: requestError,
    mutate,
  } = useSWR(`/api/v1/fulfillment-orders/${fulfillmentOrderId}/`);

  const [error, setError] = useState({
    detail: null,
    showPanel: true,
    errorsObject: {},
  });

  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [carrierData, setCarrierData] = useState({});
  const [fulfillmentId, setFulfillmentId] = useState(null);

  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (isLoading) return null;
    if (requestError) return null;

    if (fulfillmentOrder.fulfillment) {
      setFulfillmentId(fulfillmentOrder.fulfillment);
    }

    if (!fulfillmentOrder.shipping_address) {
      setError({
        detail: "no-shipping-address-error",
        showPanel: false,
        errorsObject: {},
      });
    } else if (fulfillmentOrder.available_carriers.carriers.length === 0) {
      setError({
        detail: "no-carriers-error",
        showPanel: false,
        errorsObject: {},
      });
    } else {
      setError({ detail: null, showPanel: true, errorsObject: {} });
    }

    return () => {};
  }, [fulfillmentOrder, isLoading, requestError, isValidating]);

  if (isLoading) return <LoadingState />;
  if (requestError) return null;

  const createShipment = () => {
    setCreating(true);

    posthog?.capture("Click Create Shipment");

    const data = {
      fulfillment_order_id: fulfillmentOrderId,
      package: packageData,
      carrier_specific_data: carrierData,
    };

    axios
      .post(`/api/v1/carriers/${selectedCarrier}/shipments/`, data)
      .then((response) => {
        setFulfillmentId(response.data.id);
        posthog?.capture("Shipment Created");
        mutate();
        setError({ detail: null, showPanel: true, errorsObject: {} });
        setCreating(false);
        onFulfillmentCreated();
      })
      .catch((error) => {
        setCreating(false);
        posthog?.capture("Shipment Creation Error");
        if (error.response.status === 420) {
          pushPath("/settings");
        } else if (error.response.data.detail) {
          setError({
            detail: error.response.data.detail,
            showPanel: true,
            errorsObject: {},
          });
        } else if (typeof error.response.data === "object") {
          setError({
            detail: "",
            showPanel: true,
            errorsObject: error.response.data,
          });
        }
      });
  };

  const gateways = fulfillmentOrder.order.payment_gateways.map((gateway) => {
    return gateway.name;
  });

  return (
    <>
      <TopOrderInfo
        orderName={fulfillmentOrder.order.order_name}
        fulfillmentStatus={t(fulfillmentOrder.order.fulfillment_status)}
        financialStatus={t(fulfillmentOrder.order.financial_status)}
        paymentGateways={gateways}
      />
      <Flex>
        <Box
          width="37%"
          paddingRight={5}
          paddingY={5}
          borderRight={[0, 0, "1px solid #f7f7f7"]}
        >
          <OrderInfo
            note={fulfillmentOrder.order.note}
            LocationName={fulfillmentOrder.assigned_location.name}
            LocationCountryCode={fulfillmentOrder.assigned_location.country}
            shippingAddress={fulfillmentOrder.shipping_address}
          />
          <LineItems
            items={getFulfillmentOrderLineItems(fulfillmentOrder)}
            shopSubdomain={fulfillmentOrder.shop.subdomain}
            fulfilled={fulfillmentId !== null}
          />
        </Box>
        <Box width="63%" padding={5} background="white">
          {error && (
            <Error error={error.detail} errorsObject={error.errorsObject} />
          )}
          {error.showPanel && fulfillmentId === null && (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                createShipment();
              }}
            >
              <AvailableCarriers
                selectedCarrierId={selectedCarrier}
                onCarrierChange={setSelectedCarrier}
                carriers={fulfillmentOrder.available_carriers.carriers}
              />
              <Heading size="md" marginTop="2rem">
                {t([
                  "containers.create-shipment.shipment-settings",
                  "Shipment settings",
                ])}
              </Heading>
              <Packages
                preselectedPackage={fulfillmentOrder.preselected_package}
                packages={fulfillmentOrder.shop.packages}
                onChange={setPackageData}
              />
              <CarrierRenderer
                carrierId={selectedCarrier}
                carriers={fulfillmentOrder.available_carriers.carriers}
                destination={getFulfillmentOrderDestination(fulfillmentOrder)}
                onCarrierDataChange={setCarrierData}
              />

              {gateways.includes("Cash on Delivery (COD)") && (
                <>
                  {(!fulfillmentOrder.amount_to_collect ||
                    parseFloat(fulfillmentOrder.amount_to_collect) === 0) && (
                    <Box marginY={"1rem"}>
                      <Box>
                        {t(
                          "Este pedido es contraentrega pero registra que no tiene valor por recaudar, confirma que quieres crear una guia sin recaudo contra entrega"
                        )}
                      </Box>
                      <Checkbox isRequired>
                        <strong>{t("Crear envio sin recaudo")}</strong>
                      </Checkbox>
                    </Box>
                  )}
                </>
              )}
              {!fulfillmentOrder.order.is_cod &&
                parseFloat(fulfillmentOrder.amount_to_collect) !== 0 && (
                  <Box marginY={"1rem"}>
                    <Box marginY={"0.5rem"}>
                      {t(
                        "Este pedido registra con pago pendiente por lo tanto se creará con recaudado contraentrega"
                      )}
                    </Box>
                    <Checkbox isRequired>
                      <strong>{t("Crear envio CONTRAENTREGA")}</strong>
                    </Checkbox>
                  </Box>
                )}
              <>
                {parseFloat(fulfillmentOrder.amount_to_collect) !== 0 && (
                  <Text marginY={"1rem"}>
                    {t("Valor a recaudar {{amountToCollect}}", {
                      amountToCollect: new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        // These options are needed to round to whole numbers if that's what you want.
                        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                      }).format(fulfillmentOrder.amount_to_collect),
                    })}
                  </Text>
                )}
              </>

              <Button
                size="lg"
                isFullWidth={true}
                colorScheme="teal"
                isLoading={creating}
                type="submit"
              >
                {t([
                  "containers.create-shipment.create-shipment-button",
                  "Create shipment",
                ])}
              </Button>
            </form>
          )}
          {fulfillmentId && (
            <Fulfillment
              fulfillmentId={fulfillmentId}
              onFulfillmentCancelled={() => {
                mutate();
                onFulfillmentCancelled();
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};

export default CreateShipment;
