import React, { useState, useRef, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

interface DeleteButtonProps {
  buttonText: string;
  alertTitle: string;
  alertDescription: string;
  cancelButtonText: string;
  confirmButtonText: string;
  onDelete: () => void;
  isLoading?: boolean;
  deletingStatus?: "loading" | "success" | null;
  onStatusReset?: (newState: null) => void;
  minWidth?: string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  buttonText,
  alertTitle,
  alertDescription,
  cancelButtonText,
  confirmButtonText,
  onDelete,
  deletingStatus,
  onStatusReset,
  minWidth,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cancelRef = useRef(null);

  useEffect(() => {
    if (deletingStatus === "success") {
      setIsDialogOpen(false);
      if (onStatusReset) {
        onStatusReset(null);
      }
    }
    return () => {};
  }, [deletingStatus, onStatusReset]);

  const onAlertClose = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Button
        minWidth={minWidth}
        colorScheme="red"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        {buttonText}
      </Button>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isDialogOpen}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{alertTitle}</AlertDialogHeader>
          <AlertDialogBody>{alertDescription}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onAlertClose}>
              {cancelButtonText}
            </Button>
            <Button
              ml={3}
              colorScheme="red"
              onClick={() => {
                onDelete();
                if (!deletingStatus && !onStatusReset) {
                  setIsDialogOpen(false);
                }
              }}
              isLoading={deletingStatus === "loading"}
            >
              {confirmButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DeleteButton;
