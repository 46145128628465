import React from "react";
import { useTranslation } from "react-i18next";

import CarrierSettings from "containers/CarrierSettings";

interface CoordinadoraProps {
  carrierId: number | string;
}

const Coordinadora: React.FC<CoordinadoraProps> = ({ carrierId }) => {
  const { t } = useTranslation();

  const carrierSettings = [
    {
      sectionName: t([
        "pages.carrier.coordinadora.conection-settings-name",
        "Connection settings",
      ]),
      sectionDescription: t(
        ["pages.carrier.coordinadora.conection-settings-description"],
        ""
      ),
      settings: [
        {
          fieldName: "coordinadora_id",
          fieldType: "text",
          fieldLabel: t("pages.carrier.coordinadora.coordinadora_id"),
        },
        {
          fieldName: "username",
          fieldType: "text",
          fieldLabel: t("pages.carrier.coordinadora.username"),
        },
        {
          fieldName: "password",
          fieldType: "password",
          fieldLabel: t("pages.carrier.coordinadora.password"),
        },

        {
          fieldName: "div",
          fieldType: "select",
          fieldLabel: t("pages.carrier.coordinadora.div"),
          fieldOptions: [
            <option key={0} value="00">
              00
            </option>,
            <option key={1} value="01">
              01
            </option>,
            <option key={2} value="02">
              02
            </option>,
            <option key={3} value="03">
              03
            </option>,
          ],
        },
        {
          fieldName: "account_type",
          fieldType: "select",
          fieldLabel: t("pages.carrier.coordinadora.account_type"),
          fieldOptions: [
            <option key={0} value="1">
              Cuenta corriente
            </option>,
            <option key={1} value="2">
              Acuerdo semanal
            </option>,
            <option key={2} value="3">
              Flete pago
            </option>,
          ],
        },
        {
          fieldName: "api_key",
          fieldType: "password",
          fieldLabel: t("pages.carrier.coordinadora.api_key"),
        },
        {
          fieldName: "password2",
          fieldType: "password",
          fieldLabel: t("pages.carrier.coordinadora.password2"),
        },
      ],
    },
    {
      sectionName: t([
        "pages.carrier.coordinadora.company-settings-name",
        "Company settings",
      ]),
      sectionDescription: t(
        ["pages.carrier.coordinadora.company-settings-description"],
        ""
      ),
      settings: [
        {
          fieldName: "company_name",
          fieldType: "text",
          fieldLabel: t("pages.carrier.coordinadora.company-name"),
        },
        {
          fieldName: "nit",
          fieldType: "text",
          fieldLabel: t("pages.carrier.coordinadora.nit"),
        },
        {
          fieldName: "phone",
          fieldType: "text",
          fieldLabel: t("pages.carrier.coordinadora.phone"),
        },
      ],
    },
    {
      sectionName: t([
        "pages.carrier.coordinadora.shipment-settings-name",
        "Shipment settings",
      ]),
      sectionDescription: t(
        ["pages.carrier.coordinadora.shipment-settings-description"],
        ""
      ),
      settings: [
        {
          fieldName: "use_default_declared_value",
          fieldType: "select",
          fieldLabel: t(
            "pages.carrier.coordinadora.use_default_declared_value"
          ),
          fieldOptions: [
            <option key={0} value="true">
              {t("Yes")}
            </option>,
            <option key={1} value="false">
              {t("No")}
            </option>,
          ],
          formHelperText: t(
            "pages.carrier.servientrega.use-declared-value-helper",
            "If 'No' the shipment will be generated with the order value"
          ),
        },
        {
          fieldName: "default_declared_value",
          fieldType: "number",
          fieldLabel: t("pages.carrier.coordinadora.default_declared_value"),
          inputLeftAddon: "COP",
        },
        {
          fieldName: "package_content",
          fieldType: "text",
          fieldLabel: t("pages.carrier.coordinadora.package_content"),
        },
      ],
    },
  ];

  const orderRequirements = [
    t("pages.carrier.servientrega.requirement-origin", "Origin: Colombia"),
    t(
      "pages.carrier.servientrega.requirement-destination",
      "Destination: Colombia"
    ),
    t(
      "pages.carrier.servientrega.requirement-cop-currency",
      "If the order is COD currency must be COP"
    ),
    t(
      "pages.carrier.servientrega.requirement-currency",
      'If the order is not COD currency must be COP or "use default declared value" settings must be set to "YES"'
    ),
  ];

  return (
    <CarrierSettings
      carrierId={carrierId}
      carrierSettings={carrierSettings}
      orderRequirements={orderRequirements}
    />
  );
};

export default Coordinadora;
