import { useAxios } from "moship";

export const useRateSubmit = () => {
  const axios = useAxios();
  const rateSubmit = (
    originId: number,
    rateId: number | null,
    rateSettings: any,
    typeSettings: any
  ) => {
    let path = `/api/v1/origin/${originId}/rates/`;
    let method: "post" | "put" = "post";
    if (rateId) {
      path = `/api/v1/origin/${originId}/rates/${rateId}/`;
      method = "put";
    }
    const valuesToSend = {
      ...rateSettings,
      type_settings: typeSettings,
    };

    return axios.request({ url: path, method: method, data: valuesToSend });
  };
  return rateSubmit;
};
