import React from "react";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import ErrorImage from "./images/error.png";

import { useTranslation } from "react-i18next";

interface ErrorProps {
  error?: string;
  errorsObject: {};
}

const Error: React.FC<ErrorProps> = ({ error, errorsObject }) => {
  const { t } = useTranslation();
  const errorKeys = Object.keys(errorsObject);
  if (errorKeys.length !== 0) {
    const errors = errorKeys.map((key, index) => {
      const innerKeys = Object.keys(errorsObject[key]);
      const innerErrors = innerKeys.map((innerKey, index) => {
        return (
          <Text key={index}>
            {key} {innerKey}: {errorsObject[key][innerKey]}
          </Text>
        );
      });
      return (
        <Alert status="error" marginBottom="1rem" key={index}>
          <AlertIcon />
          <AlertTitle>{innerErrors}</AlertTitle>
        </Alert>
      );
    });
    return <Box>{errors}</Box>;
  }

  if (!error) return null;
  let extraError = null;
  if (error === "no-shipping-address-error" || error === "no-carriers-error") {
    extraError = (
      <Center marginTop="1rem" shadow="md" height="calc(100% - 45px)">
        <Box textAlign="center" maxWidth="550px">
          <Center marginBottom="1rem">
            <Image src={ErrorImage} width="50px" />
          </Center>
          <Heading size="lg">{t(`errors.${error}`, error)}</Heading>
          <Text>{t(`errors.${error}-description`, "")}</Text>
        </Box>
      </Center>
    );
  }

  return (
    <>
      <Alert status="error" marginBottom="1rem">
        <AlertIcon />
        <AlertTitle>{t(`errors.${error}`, error)}</AlertTitle>
      </Alert>
      {extraError && extraError}
    </>
  );
};

export default Error;
