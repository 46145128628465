import React from "react";

import { Box, Text, Avatar } from "@chakra-ui/react";
import { UserIdProps } from "./index";

const CompactUserId: React.FC<UserIdProps> = ({ name, shopSubdomain }) => {
  return (
    <Box
      display="flex"
      width="100%"
      padding="13px 5px 13px 8px"
      justifyContent="space-between"
      sx={{
        "&:hover": {
          background: "#F7FAFC",
          cursor: "pointer",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="left"
        fontSize="16px"
        lineHeight="18px"
        marginBottom="4px"
      >
        <Text>{name}</Text>
        <Text fontWeight="700" textTransform="uppercase">
          {shopSubdomain}
        </Text>
      </Box>
      <Box>
        <Avatar name={name} width="45px" height="45px" marginLeft="10px" />
      </Box>
    </Box>
  );
};

export default CompactUserId;
