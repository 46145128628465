import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";

import Fulfillments from "containers/Fulfillments";

interface OrderDrawerProps {
  orderId: number;
  onDrawerClose: () => void;
  orderName?: string;
}

const OrderDrawer: React.FC<OrderDrawerProps> = ({
  orderId,
  onDrawerClose,
  orderName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const onFulfillmentOrderClick = (fulfillmentOrderId) => {
    if (fulfillmentOrderId) {
      searchParams.set("fulfillment_order", fulfillmentOrderId);
    } else {
      searchParams.delete("fulfillment_order");
    }
    history.push({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <>
      <Drawer
        isOpen={orderId !== null}
        onClose={() => {
          onDrawerClose();
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {t(["pages.orders.drawer-title", "Order"], "Order", {
              orderName,
            })}
          </DrawerHeader>
          <DrawerBody>
            <Fulfillments
              orderId={orderId}
              onFulfillmentOrderClick={onFulfillmentOrderClick}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default OrderDrawer;
