import React from "react";
import { useTranslation } from "react-i18next";

import { Flex, Box, Heading, Text, Image } from "@chakra-ui/react";

import noImage from "./images/no-image.jpg";

interface ItemsBoxProps {
  locationName: string;
  items: Array<{
    image: string;
    title: string;
    sku: string;
    quantity: number;
  }>;
  children?: any;
  decorator?: any;
}

const ItemsBox: React.FC<ItemsBoxProps> = ({
  locationName,
  items,
  children,
  decorator,
}) => {
  const { t } = useTranslation();
  const lineItems = items.map((item, index) => {
    const image = item.image ? item.image : noImage;
    return (
      <Flex
        justifyContent="space-between"
        borderBottom="1px solid #e7e7e7"
        paddingY="1rem"
        alignItems="center"
        key={index}
      >
        <Flex alignItems="center">
          <Box marginRight="1rem">
            <Image src={image} maxW="50px" />
          </Box>
          <Box>
            <Heading size="md">{item.title}</Heading>
            <Text size="sm">SKU: {item.sku}</Text>
          </Box>
        </Flex>
        <Box>
          <Heading size="md" width="max-content">
            x {item.quantity}
          </Heading>
        </Box>
      </Flex>
    );
  });
  return (
    <Box
      padding="1rem 1rem 1rem 1rem"
      marginY="1rem"
      borderRadius="4px"
      border="1px solid #3333334d"
    >
      <Flex justifyContent="space-between">
        <Box>
          <Heading size="md">
            {t(["components.items-box.location", "Location"], "Location")}
          </Heading>
          <Text>{locationName}</Text>
        </Box>
        <Box>{decorator}</Box>
      </Flex>
      {lineItems}
      {children}
    </Box>
  );
};

export default ItemsBox;
