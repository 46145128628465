import Cookies from "js-cookie";

const indexSessionDetector = {
  name: "indexSessionDetector",
  lookup(options) {
    return "es";
    // const localesValues = Cookies.get("locales");
    // if (!localesValues) return null;
    // const locales = localesValues.split(",");
    // const pathName = window.location.pathname;
    // const pathArray = pathName.split("/");
    // const indexSessionVal = pathArray[1];
    // if (isNaN(parseInt(indexSessionVal))) return null;
    // return locales[indexSessionVal];
  },
  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  },
};

export default indexSessionDetector;
