import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Dashboard from "containers/Dashboard";
import Container from "components/Container";

import { useSWR } from "moship";

import Coordinadora from "./Colombia/Coordinadora";
import Servientrega from "./Colombia/Servientrega";

interface CarrierProps {}

const Carrier: React.FC<CarrierProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { carrierId } = useParams<{ carrierId: string }>();
  const {
    data: carrier,
    isLoading,
    error,
  } = useSWR(`/api/v1/carriers/${carrierId}`);

  if (isLoading) return null;
  if (error) return null;

  const get_carrier_settings = () => {
    switch (carrier.provider) {
      case "coordinadora_colombia":
        return <Coordinadora carrierId={carrierId} />;
      case "servientrega_colombia":
        return <Servientrega carrierId={carrierId} />;
      case "tcc_colombia":
        return <Coordinadora carrierId={carrierId} />;
      default:
        return null;
    }
  };
  const carrier_settings = get_carrier_settings();
  return (
    <Dashboard>
      <Container
        title={t(["pages.carrier.title", "Settings"], {
          provider: carrier.get_provider_display,
        })}
        onGoBack={() => {
          history.goBack();
        }}
      >
        {carrier_settings}
      </Container>
    </Dashboard>
  );
};

export default Carrier;
