import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import PackageForm from "containers/PackageForm";
import CountryCarriers from "containers/CountryCarriers";

import Welcome from "./Welcome";
import { useSWR } from "moship";
import Cookies from "js-cookie";
import Background from "images/background-waves.jpg";

interface OnboardProps {}

const Onboard: React.FC<OnboardProps> = () => {
  const { data: packages } = useSWR("/api/v1/packages");
  const [hasPackages, setHasPackages] = useState(false);
  const { userIndex } = useParams<{ userIndex: string }>();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (typeof packages === "undefined") return null;
    if (packages.length !== 0) {
      setHasPackages(true);
    }
    return () => {};
  }, [packages]);

  // Add cookie for remaining shipments ribbon
  const url = new URL(window.location.href);
  const cookieName = `free-shipments-ribbon-${userIndex}`;
  let params = new URLSearchParams(url.search);
  if (params.get("free-shipments-ribbon") === "true") {
    Cookies.set(cookieName, "true");
  }

  return (
    <Box backgroundImage={`url(${Background})`} backgroundSize="contain">
      <Center minHeight="100vh" backgroundColor="rgba(255,255,255,0.7)">
        <Box
          shadow="md"
          background="#fff"
          minWidth="550px"
          maxW={["90%", "90%", "650px"]}
          padding="2rem"
        >
          {step === 1 && (
            <Welcome
              onClick={() => {
                if (hasPackages) {
                  setStep(3);
                } else {
                  setStep(2);
                }
              }}
            />
          )}
          {step === 2 && (
            <PackageForm
              onPackageSave={() => {
                setStep(3);
              }}
              submitText={t("Continue")}
            />
          )}
          {step === 3 && (
            <Box>
              <Text>
                {t(
                  "pages.onboard.credentials-description",
                  "Now you'll need your servientrega credentials, if you dont have them, contact your agent and ask for your credentials, them come back"
                )}
              </Text>
              <Flex justifyContent="flex-end" marginTop="1rem">
                <CountryCarriers country="CO" />
              </Flex>
            </Box>
          )}
        </Box>
      </Center>
    </Box>
  );
};

export default Onboard;
