import * as React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSWR } from "moship";
import {
  Text,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Spinner,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Box,
  Badge,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import AddRateModal from "./AddRateModal";
import Pagination from "containers/Pagination";

interface LocationsProps {}

const Locations: React.FC<LocationsProps> = () => {
  const history = useHistory();
  const { originId } = useParams<{ originId: string }>();
  const [modalCityId, setModalCityId] = useState(0);
  const [searchLoading, setsearchLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();

  const { search: searchQuery } = useLocation();
  var searchParams = new URLSearchParams(searchQuery);

  let defaultPage = searchParams.get("page");

  defaultPage = defaultPage ? defaultPage : "1";

  let defaultSearch = searchParams.get("search");
  defaultSearch = defaultSearch ? defaultSearch : "";

  const [searchInput, setSearchInput] = useState(defaultSearch);

  let path = `/api/v1/origin/${originId}/locations/?search=${defaultSearch}&page=${defaultPage}`;
  const { data: locations, mutate: locationsMutate } = useSWR(path);

  useEffect(() => {
    if (searchInput && ready === true) {
      setsearchLoading(true);
    }
    setReady(true);
    const timeout = setTimeout(() => {
      var searchParams = new URLSearchParams(searchQuery);
      searchParams.set("search", searchInput);
      searchParams.set("page", "1");
      setsearchLoading(false);
      history.replace({
        search: `?${searchParams.toString()}`,
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  return (
    <Box marginBottom={"10rem"}>
      <Flex justifyContent={"space-between"} flexWrap="wrap">
        <Heading width={["100%", "50%"]} marginBottom={["0.5rem", 0]}>
          {t("Cities")}
        </Heading>
        <HStack maxW={"350px"} width={["100%", "50%"]}>
          <InputGroup>
            <Input
              placeholder={t("Search")}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <InputRightAddon>
              {searchLoading && <Spinner size={"sm"} />}
              {!searchLoading && <BiSearch />}
            </InputRightAddon>
          </InputGroup>
        </HStack>
      </Flex>

      <Divider marginY={"1rem"} />

      <Table size={"sm"}>
        <Thead>
          <Tr fontWeight={"900"}>
            <Td>{t("City")}</Td>
            <Td>{t("State")}</Td>
            <Td textAlign={"center"}>{t("Rates")}</Td>
            <Td textAlign="right">{t("Actions")}</Td>
          </Tr>
        </Thead>
        <Tbody>
          {locations &&
            locations.results.map((location, index) => {
              return (
                <Tr key={location.id}>
                  <Td>{location.name}</Td>
                  <Td>{location.state}</Td>
                  <Td textAlign={"center"}>
                    {location.rates.length === 0 && (
                      <Text>{t("No rates")}</Text>
                    )}
                    {location.rates.length !== 0 && (
                      <VStack>
                        {location.rates.map((rate) => {
                          return (
                            <Badge key={rate.id}>
                              {rate.internal_name
                                ? rate.internal_name
                                : rate.name}{" "}
                              -{" "}
                              {rate.price_is_calculated
                                ? t("calculated", "Calculated")
                                : `$${rate.price}`}
                            </Badge>
                          );
                        })}
                      </VStack>
                    )}
                  </Td>
                  <Td textAlign="right">
                    <Button
                      size={"sm"}
                      onClick={() => {
                        setModalCityId(location.id);
                      }}
                    >
                      {t("Add rate")}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {locations && (
        <Flex justifyContent={"center"} marginY={"1rem"}>
          <Pagination next={locations.next} previous={locations.previous} />
        </Flex>
      )}
      <AddRateModal
        cityId={modalCityId}
        originId={originId}
        onClose={() => {
          setModalCityId(0);
          locationsMutate();
        }}
      />
    </Box>
  );
};

export default Locations;
