import { Button, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import React from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

interface PaginationProps {
  next: string | null;
  previous: string | null;
}

const Pagination: React.FC<PaginationProps> = ({ next, previous }) => {
  const history = useHistory();

  return (
    <HStack>
      <Button
        disabled={previous === null}
        onClick={() => {
          const url = new URL(previous);
          var searchParams = new URLSearchParams(url.search);
          history.replace({
            search: `?${searchParams.toString()}`,
          });
        }}
      >
        <GrFormPrevious size={30} />
      </Button>
      <Button
        disabled={next === null}
        onClick={() => {
          const url = new URL(next);
          var searchParams = new URLSearchParams(url.search);
          history.replace({
            search: `?${searchParams.toString()}`,
          });
        }}
      >
        <GrFormNext size={30} />
      </Button>
    </HStack>
  );
};

export default Pagination;
