import * as React from "react";

import { AppRouter } from "./AppRouter";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// import { getAllJSDocTagsOfKind } from "typescript";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};
export const theme = extendTheme({
  colors,
  fonts: {},
});

function App() {
  posthog.init("phc_wlNFVuyUSEwSR0Lew1quqMPnqvZlBXotAzKbpr8XjHF", {
    api_host: "https://app.posthog.com",
    autocapture: false,
  });

  return (
    <ChakraProvider theme={theme}>
      <PostHogProvider client={posthog}>
        <AppRouter />
      </PostHogProvider>
    </ChakraProvider>
  );
}

export default App;
