import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import PriceColumn from "./PriceColumn";

interface priceColumnsProps {
  id: number;
  title: string;
  description: string;
  price: number;
  disabled: boolean;
  buttonText: string;
  featuresTitle: string;
  features: Array<string>;
}

interface PriceTableProps {
  prices: Array<priceColumnsProps>;
  onPriceClick: (id: number) => void;
}

const PriceTable: React.FC<PriceTableProps> = ({ prices, onPriceClick }) => {
  const pricesRender = prices.map((price, index) => {
    return <PriceColumn {...price} onClick={onPriceClick} key={index} />;
  });
  return (
    <Box overflowX="auto">
      <SimpleGrid
        columns={3}
        border="2px solid"
        borderColor="blackAlpha.300"
        minWidth="650px"
        width="100%"
      >
        {pricesRender}
      </SimpleGrid>
    </Box>
  );
};

export default PriceTable;
