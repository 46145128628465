import React from "react";

import { useSWR } from "moship";

import LoadingBox from "./LoadingBox";
import ItemsBox from "components/ItemsBox";
import FulfillmentTag from "components/FulfillmentTag";

import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

interface FulfillmentsProps {
  orderId: number;
  onFulfillmentOrderClick: (fulfillmentOrderId: number) => void;
}

const Fulfillments: React.FC<FulfillmentsProps> = ({
  orderId,
  onFulfillmentOrderClick,
}) => {
  const { t } = useTranslation();
  const orderPath = `/api/v1/orders/${orderId}/`;
  const { data: order, error, isLoading } = useSWR(orderPath);

  if (error) return null;
  if (isLoading) return <LoadingBox />;

  const fulfillmentOrdersRender = order.fulfillment_orders.map(
    (fulfillmentOrder, index) => {
      if (fulfillmentOrder.status !== "open") return null;

      const items = fulfillmentOrder.line_items.map((lineItem, index) => {
        return {
          image: lineItem.scaled_img,
          title: lineItem.name,
          sku: lineItem.sku,
          quantity: lineItem.remaining_quantity,
        };
      });

      const actions = fulfillmentOrder.supported_actions.map(
        (action, index) => {
          if (action === "create_fulfillment") {
            return (
              <Button
                colorScheme="teal"
                variant="solid"
                key={index}
                onClick={() => {
                  onFulfillmentOrderClick(fulfillmentOrder.id);
                }}
              >
                {t([`containers.fulfillments.action-${action}`])}
              </Button>
            );
          } else {
            return null;
          }
        }
      );

      return (
        <ItemsBox
          key={index}
          decorator={<FulfillmentTag state={fulfillmentOrder.status} />}
          locationName={fulfillmentOrder.assigned_location.name}
          items={items}
        >
          <>
            {fulfillmentOrder.supported_actions.length === 0 && (
              <Box textAlign="center" marginTop="1.5rem">
                <Heading textAlign="center" size="md">
                  {t(
                    "containers.fulfillments.no-supported-actions",
                    "This order doesn't have supported actions"
                  )}
                </Heading>
                <Button
                  as="a"
                  target="_blank"
                  marginTop="1rem"
                  colorScheme="teal"
                  href={fulfillmentOrder.order.shopify_uri}
                >
                  {t(
                    "containers.fulfillments.open-order-on-shopify",
                    "Open on Shopify"
                  )}
                </Button>
              </Box>
            )}
            <Flex justifyContent="flex-end" marginTop="1rem">
              <HStack>{actions}</HStack>
            </Flex>
          </>
        </ItemsBox>
      );
    }
  );

  const fulfillmentsRender = order.fulfillments.map((fulfillment, index) => {
    if (fulfillment.status !== "success" && fulfillment.status !== "cancelled")
      return null;

    const items = fulfillment.line_items.map((lineItem, index) => {
      const item = {
        image: lineItem.scaled_img,
        title: lineItem.name,
        sku: lineItem.sku,
        quantity: lineItem.quantity,
      };
      return item;
    });

    return (
      <ItemsBox
        key={index}
        locationName={fulfillment.location.name}
        items={items}
        decorator={<FulfillmentTag state={fulfillment.status} />}
      >
        <Flex justifyContent="flex-end" marginTop="1rem">
          <HStack>
            {fulfillment.fulfillment_order !== null && (
              <Button
                colorScheme="teal"
                variant="solid"
                key={index}
                onClick={() => {
                  onFulfillmentOrderClick(fulfillment.fulfillment_order.id);
                }}
              >
                {t("containers.fulfillments.action-see_details")}
              </Button>
            )}
          </HStack>
        </Flex>
        {fulfillment.fulfillment_order === null && (
          <Box textAlign="center" marginY="1.5rem">
            <Heading textAlign="center" size="md">
              {t(
                "containers.fulfillments.fulfilled-outside",
                "This order doesn't have supported actions"
              )}
            </Heading>
            <Button
              as="a"
              target="_blank"
              marginTop="1rem"
              colorScheme="teal"
              href={order.shopify_uri}
            >
              {t(
                "containers.fulfillments.open-order-on-shopify",
                "Open on Shopify"
              )}
            </Button>
          </Box>
        )}
      </ItemsBox>
    );
  });

  return (
    <>
      {order.note && (
        <Box padding="1rem" background="blackAlpha.100" borderRadius="4px">
          <Heading size="md" marginBottom="0.5rem">
            {t("containers.fulfillments.order-notes", "Order notes")}
          </Heading>
          <Text>{order.note}</Text>
        </Box>
      )}
      <SimpleGrid spacing={0}>
        {fulfillmentOrdersRender}
        {fulfillmentsRender}
      </SimpleGrid>
    </>
  );
};

export default Fulfillments;
