import React, { useEffect } from "react";

import { useSWR, useAxios } from "moship";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import PriceTableComp from "components/PriceTable";
import { usePostHog } from "posthog-js/react";
import { useToast } from "@chakra-ui/react";

interface PriceTableProps {}

const PriceTable: React.FC<PriceTableProps> = () => {
  const posthog = usePostHog();
  const axios = useAxios();
  const toast = useToast();

  const { t } = useTranslation();
  const { search } = useLocation();
  const { data: plans, isLoading, error, mutate } = useSWR("/api/v1/plans/");
  const { mutate: subscriptionMutate } = useSWR("/api/v1/subscription/");

  var searchParams = new URLSearchParams(search);
  const chargeId = searchParams.get("charge_id");

  useEffect(() => {
    posthog?.capture("Print Price Table");
    return () => {};
  }, [posthog]);

  useEffect(() => {
    if (chargeId) {
      axios.get(`/api/v1/recurring-application-charges/`).then((response) => {
        mutate();
        subscriptionMutate();
      });
    }

    return () => {};
  }, [chargeId, mutate, axios, subscriptionMutate]);

  if (isLoading) return null;
  if (error) return null;

  const prices = plans.map((plan, index) => {
    let features = plan.features_list.split(",").map((feature) => {
      return t(feature);
    });

    return {
      id: plan.id,
      title: plan.title,
      description: t(plan.description),
      price: plan.price,
      disabled: plan.is_shop_current,
      buttonText: plan.is_shop_current ? t("Current") : t("Purchase"),
      featuresTitle: t("Your subscription include"),
      features: features,
    };
  });

  const onPriceClick = (priceId) => {
    posthog?.capture("Clicked Price Table Plan Activate");
    axios
      .post("/api/v1/recurring-application-charges/", { plan_id: priceId })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = response.data.confirmation_url;
        }
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.response.data.detail,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return <PriceTableComp prices={prices} onPriceClick={onPriceClick} />;
};

export default PriceTable;
