import React from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  FormLabel,
  SimpleGrid,
  Image,
  VStack,
  Heading,
} from "@chakra-ui/react";

import envelope from "images/envelope.png";
import box from "images/box.png";

interface PackageTypeSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

const PackageTypeSelector: React.FC<PackageTypeSelectorProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const options = [
    { name: "bag", img: envelope },
    { name: "box", img: box },
  ];
  return (
    <>
      <FormLabel>
        {t("containers.package-form.package-type", "Package type")}
      </FormLabel>
      <SimpleGrid columns={2} spacing={4}>
        {options.map((option, index) => {
          const borderColor = option.name === value ? "#000" : "transparent";
          return (
            <Flex
              key={index}
              background="blackAlpha.100"
              padding="1rem"
              justify="center"
              flexWrap="wrap"
              borderRadius="4px"
              cursor="pointer"
              border={`2px solid ${borderColor}`}
              onClick={() => {
                onChange(option.name);
              }}
            >
              <VStack paddingTop="1rem">
                <Image src={option.img} marginBottom="0.5rem" maxW="5rem" />
                <Heading size="sm" textTransform="capitalize">
                  {t(option.name)}
                </Heading>
              </VStack>
            </Flex>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default PackageTypeSelector;
