import React, { useState } from "react";

import { useSWR, useAxios } from "moship";
import { useTranslation } from "react-i18next";

import { printBinary } from "utils/printer";

import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

import BondIconSrc from "./images/bond-icon.png";
import HorizontalStickerSrc from "./images/horizontal-sticker-icon.png";
import SquareStickerSrc from "./images/horizontal-sticker-icon.png";

import { usePostHog } from "posthog-js/react";

interface ShipmentLabelsProps {
  shipmentId: number;
}

const ShipmentLabels: React.FC<ShipmentLabelsProps> = ({ shipmentId }) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const posthog = usePostHog();
  const [printingLabel, setPrintingLabel] = useState({});
  const [labelPrintError, setLabelPrintError] = useState(null);
  const {
    data: shipmentLabels,
    isLoading,
    error,
  } = useSWR(`/api/v1/shipments/${shipmentId}/shipment-labels/`);

  if (isLoading) return null;
  if (error) return null;

  const labelsRender = shipmentLabels.map((label, index) => {
    let src = null;
    if (label.icon === "bond") {
      src = BondIconSrc;
    } else if (label.icon === "sticker-horizontal") {
      src = HorizontalStickerSrc;
    } else if (label.icon === "sticker-square") {
      src = SquareStickerSrc;
    }

    const onLoadingChange = (labelType, state) => {
      setPrintingLabel({ ...printingLabel, [labelType]: state });
    };

    const onLabelClick = (labelId, labelType) => {
      onLoadingChange(labelType, true);
      posthog?.capture("Click Print Shipment Label");
      axios
        .get(`/api/v1/shipment-labels/${labelId}/`)
        .then((response) => {
          onLoadingChange(labelType, false);
          printBinary(response.data.file);
          setLabelPrintError(null);
        })
        .catch((error) => {
          setLabelPrintError(error.response.data.detail);
          onLoadingChange(labelType, false);
        });
    };

    return (
      <Flex
        padding="1rem"
        shadow="md"
        justifyContent="space-between"
        alignItems="center"
        key={index}
      >
        <Box>
          <HStack>
            <Center width="60px" height="60px" marginRight="0.5rem">
              <Image src={src} />
            </Center>
            <Text>
              {t(
                `containers.shipment-labels.label-name.${label.carrier}-${label.type}`
              )}
            </Text>
          </HStack>
        </Box>
        <Box>
          <Button
            isLoading={printingLabel[label.type]}
            onClick={() => {
              onLabelClick(label.id, label.type);
            }}
          >
            {t("containers.shipment-labels.print", "Print label")}
          </Button>
        </Box>
      </Flex>
    );
  });

  return (
    <>
      {labelPrintError && (
        <>
          <Alert status="error" marginBottom="1rem">
            <AlertIcon />
            <AlertTitle>{t(labelPrintError)}</AlertTitle>
          </Alert>
        </>
      )}
      <SimpleGrid columns={1} spacing={4}>
        {labelsRender}
      </SimpleGrid>
    </>
  );
};

export default ShipmentLabels;
