import React, { useEffect } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
  Heading,
  SimpleGrid,
  Image,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import getCarrierLogo from "utils/getCarrierLogo";

interface AvailableCarriersProps {
  selectedCarrierId: number | string | null;
  onCarrierChange: (carrierId: number | string) => void;
  carriers: Array<{ id: number; provider: string; name: string }>;
  error?: string;
}

const AvailableCarriers: React.FC<AvailableCarriersProps> = ({
  selectedCarrierId,
  onCarrierChange,
  carriers,
  error,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedCarrierId === null && carriers.length > 0) {
      onCarrierChange(carriers[0].id);
    }
    return () => {};
  }, [selectedCarrierId, carriers, onCarrierChange]);

  const errorAlert = error ? (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>{t(error)}</AlertTitle>
    </Alert>
  ) : null;

  const carrierRender = carriers.map((carrier, index) => {
    let className = "";
    if (carrier.id === selectedCarrierId) {
      className += " selected";
    }

    const Logo = getCarrierLogo(carrier.provider);

    return (
      <VStack key={index} spacing={0}>
        <Center
          shadow="md"
          className={className}
          sx={{ "&.selected": { background: "#e6e6e6" } }}
          paddingX={4}
          cursor="pointer"
          onClick={() => {
            onCarrierChange(carrier.id);
          }}
        >
          <VStack>
            <Heading size="md">
              <Image src={Logo} />
            </Heading>
          </VStack>
        </Center>
        <Text>{carrier.name}</Text>
      </VStack>
    );
  });

  return (
    <>
      <Heading size="md" marginBottom="0.5rem">
        {t([
          "containers.create-shipment.available-carriers.carriers",
          "Carriers",
        ])}
      </Heading>
      {error && errorAlert}
      <SimpleGrid columns={3} spacing={4}>
        {carrierRender}
      </SimpleGrid>
    </>
  );
};

export default AvailableCarriers;
