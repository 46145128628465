import { Button, Flex, Image, Text, Heading, Box } from "@chakra-ui/react";
import React from "react";
import Empty from "./images/emptystate-files.png";

interface EmptyStateProps {
  title: string;
  description: string;
  primaryActionText?: string;
  onPrimaryAction?: () => void;
  secondaryActionText?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  description,
  primaryActionText,
  onPrimaryAction,
  secondaryActionText,
}) => {
  return (
    <>
      <Box textAlign="center">
        <Flex justifyContent="center">
          <Image src={Empty} />
        </Flex>
        <Heading size="md" marginBottom="1rem">
          {title}
        </Heading>
        <Text marginBottom="1.2rem">{description}</Text>
        <Flex justifyContent="center">
          {secondaryActionText && (
            <Button colorScheme="teal">{secondaryActionText}</Button>
          )}
          {primaryActionText && (
            <Button
              colorScheme="teal"
              marginRight="0.5rem"
              onClick={onPrimaryAction}
            >
              {primaryActionText}
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default EmptyState;
