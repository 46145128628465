import i18n from "i18next";
// import Backend from "i18next-locize-backend";
// import { i18nextPlugin } from "translation-check";

import indexSessionDetector from "./indexSessionDetector";

import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./langs/en.json";
import es from "./langs/es.json";

const languageDetector = new LanguageDetector();

languageDetector.addDetector(indexSessionDetector);

// const locizeOptions = {
//   projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || "",
//   apiKey: process.env.REACT_APP_LOCIZE_API_KEY || "",
//   referenceLng: "en",
//   version: "latest",
//   private: false,
//   allowedAddOrUpdateHosts: ["localhost"],
// };

const resources = {
  en: en,
  es: es,
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ["indexSessionDetector", "navigator"],
    },
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    // backend: locizeOptions,
    saveMissing: true,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
