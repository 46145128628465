import CoordinadoraLogo from "./images/coordinadora-logo.png";
import ServientregaLogo from "./images/servientrega-logo.png";

const getCarrierLogo = (provider: string): string => {
  switch (provider) {
    case "coordinadora_colombia":
      return CoordinadoraLogo;
    case "servientrega_colombia":
      return ServientregaLogo;
    default:
      return "";
  }
};

export default getCarrierLogo;
