import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Flex, Button, Text } from "@chakra-ui/react";
import { usePushPath, useAxios } from "moship";
import { useTranslation } from "react-i18next";

interface RemainingShipmentRibbonProps {}

const RemainingShipmentRibbon: React.FC<RemainingShipmentRibbonProps> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { userIndex } = useParams<{ userIndex: string }>();
  const pushPath = usePushPath();

  const [remainingFreeShipping, setRemainingFreeShipping] = useState(0);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [cookie, setCookie] = useState(null);

  const url = new URL(window.location.href);

  const cookieName = `free-shipments-ribbon-${userIndex}`;

  useEffect(() => {
    let params = new URLSearchParams(url.search);

    if (params.get("free-shipments-ribbon") === "true") {
      Cookies.set(cookieName, "true");
      setCookie("true");
    } else if (Cookies.get(cookieName) && !params.get("charge_id")) {
      setCookie(Cookies.get(cookieName));
    }
    return () => {};
  }, [cookieName, url.search]);

  useEffect(() => {
    if (cookie === "true") {
      axios.get("/api/v1/shop/").then((response) => {
        setRemainingFreeShipping(response.data.remaining_free_shipments);
        setCurrentPlan(response.data.active_app_charge);
        if (response.data.active_app_charge !== null) {
          Cookies.remove(cookieName);
        }
      });
    }
    return () => {};
  }, [cookie, axios, cookieName]);

  if (cookie !== "true") return null;

  if (
    remainingFreeShipping <= 30 &&
    remainingFreeShipping >= 0 &&
    currentPlan === null
  ) {
    return (
      <Flex
        background="yellow.300"
        padding="10px 10px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontWeight="bold" fontSize="lg">
          {t(
            "containers.dashboard.remaining-free-shipments.remaining",
            "You have {{remainingFreeShipping}} free shipments available",
            { remainingFreeShipping }
          )}
        </Text>
        <Button
          colorScheme="teal"
          onClick={() => {
            pushPath("/settings");
          }}
        >
          {t(
            "containers.dashboard.remaining-free-shipments.select-plan",
            "Select plan"
          )}
        </Button>
      </Flex>
    );
  } else {
    return null;
  }
};

export default RemainingShipmentRibbon;
