import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSWR, useAxios } from "moship";
import * as React from "react";
import { useState } from "react";

import { MdChevronLeft } from "react-icons/md";

interface RateCitiesProps {
  rateId: number;
  originId: string;
  onBack?: () => void;
}

const RateCities: React.FC<RateCitiesProps> = ({
  rateId,
  originId,
  onBack,
}) => {
  const { t } = useTranslation();
  const { data: rate } = useSWR(`/api/v1/origin/${originId}/rates/${rateId}/`);
  const [addOrDelete, setAddOrDelete] = useState<"add" | "delete">("add");
  const axios = useAxios();
  const defaultValues = {
    places: "",
  };
  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const submit = () => {
    setIsLoading(true);
    axios
      .request({
        method: addOrDelete === "add" ? "post" : "delete",
        url: `/api/v1/origin/${originId}/rates/${rateId}/places/`,
        data: values,
      })
      .then((response) => {
        if (response.status === 200) {
          setValues(defaultValues);
        }
      })
      .catch(() => {
        alert("Error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!rate) return null;

  return (
    <>
      <Box>
        {onBack && (
          <Button
            leftIcon={<MdChevronLeft />}
            onClick={onBack}
            marginBottom={"1rem"}
          >
            {t("back")}
          </Button>
        )}
        <Heading size="md">{rate.internal_name || rate.name}</Heading>
      </Box>
      <Box paddingY={"1rem"}>
        <Divider />
      </Box>
      <Tabs
        isFitted
        variant="solid-rounded"
        onChange={(index) => {
          if (index === 0) {
            setAddOrDelete("add");
          } else {
            setAddOrDelete("delete");
          }
        }}
      >
        <TabList mb="1em">
          <Tab>{t("Add cities")}</Tab>
          <Tab>{t("Remove cities")}</Tab>
        </TabList>
      </Tabs>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <VStack spacing={2}>
          {/* <FormControl>
            <FormLabel>{t("Province")}</FormLabel>
            <Select value={values.province} onChange={onChange("province")}>
              <option value=""></option>
              {Object.keys(states).map((stateCode, index) => {
                return (
                  <option key={index} value={stateCode}>
                    {states[stateCode]}
                  </option>
                );
              })}
            </Select>
          </FormControl> */}
          <FormControl>
            <FormLabel>{t("Municipalities")}</FormLabel>
            <Textarea
              placeholder={t("City,State")}
              rows={10}
              value={values.places}
              onChange={onChange("places")}
              required
            />
            <FormHelperText>
              {t("Enter a city and state separated by comma in each line")}
            </FormHelperText>
          </FormControl>
          <Flex justifyContent={"flex-end"} width={"100%"}>
            <Button type="submit" colorScheme={"teal"} isLoading={isLoading}>
              {addOrDelete === "add" ? t("Add cities") : t("Remove cities")}
            </Button>
          </Flex>
        </VStack>
      </form>
    </>
  );
};

export default RateCities;
