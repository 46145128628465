import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Box,
  Select,
  FormHelperText,
  Flex,
} from "@chakra-ui/react";

import { useAxios } from "moship";

import PackageTypeSelector from "./PackageTypeSelector";

interface PackageFormProps {
  onPackageSave: () => void;
  submitText?: string;
  packageId?: number | null;
}

const PackageForm: React.FC<PackageFormProps> = ({
  packageId,
  onPackageSave,
  submitText,
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const defaultValues = {
    type: "bag",
    name: "",
    unit_of_length: "cm",
    height: 30,
    width: 20,
    length: 2,
    unit_of_mass: "Kg",
    weight: 1,
  };
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    if (packageId) {
      axios.get(`/api/v1/packages/${packageId}/`).then((response) => {
        setValues(response.data);
      });
    }

    return () => {
      setValues(defaultValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId]);

  const onChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const createPackage = () => {
    axios
      .post("/api/v1/packages/", values)
      .then((response) => {
        setValues(defaultValues);
        onPackageSave();
      })
      .catch((error) => {});
  };

  const updatePackage = (packageId) => {
    axios
      .put(`/api/v1/packages/${packageId}/`, values)
      .then((response) => {
        setValues(defaultValues);
        onPackageSave();
      })
      .catch((error) => {});
  };

  const onSubmit = () => {
    if (packageId) {
      updatePackage(packageId);
    } else {
      createPackage();
    }
  };

  return (
    <form
      id="packageForm"
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <VStack spacing={3}>
        <Box width="100%">
          <PackageTypeSelector
            value={values.type}
            onChange={(value) => {
              setValues({ ...values, type: value });
            }}
          />
        </Box>
        <FormControl>
          <FormLabel>
            {t("containers.package-form.package-name", "Package name")}
          </FormLabel>
          <Input value={values.name} onChange={onChange("name")} required />
          <FormHelperText>
            {t(
              "containers.package-form.package-name-helper",
              "Name for internal identification"
            )}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>
            {t("containers.package-form.unit-of-length", "Unit of length")}
          </FormLabel>
          <Select
            value={values.unit_of_length}
            onChange={onChange("unit_of_length")}
            required
          >
            <option value="cm">{t("Centimeters")}</option>
            {/* <option value="in">Inches</option> */}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>{t("Lenght")}</FormLabel>
          <Input
            type="number"
            value={values.length}
            onChange={onChange("length")}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("Height")}</FormLabel>
          <Input
            type="number"
            value={values.height}
            onChange={onChange("height")}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("Width")}</FormLabel>
          <Input
            type="number"
            value={values.width}
            onChange={onChange("width")}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            {t("containers.package-form.unit-of-mass", "Unit of mass")}
          </FormLabel>
          <Select
            value={values.unit_of_mass}
            onChange={onChange("unit_of_mass")}
            required
          >
            <option value="Kg">Kilos</option>
            {/* <option value="Lb">Pounds</option> */}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>{t("Weight")}</FormLabel>
          <Input
            type="number"
            value={values.weight}
            onChange={onChange("weight")}
            required
          />
        </FormControl>
      </VStack>
      {submitText && (
        <Flex marginTop="1rem" justifyContent="flex-end">
          <Button colorScheme="teal" type="submit" form="packageForm">
            {submitText}
          </Button>
        </Flex>
      )}
    </form>
  );
};

export default PackageForm;
