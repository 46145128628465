import React from "react";

import UserID from "components/Header/UserId";
import {
  Image,
  Box,
  Flex,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Avatar,
  Divider,
  IconButton,
} from "@chakra-ui/react";

import { GiHamburgerMenu } from "react-icons/gi";

import Logo from "./images/Logo.png";
import { useTranslation } from "react-i18next";

export interface HeaderProps {
  userIndex: number;
  shops: Array<{
    userFullName: string;
    shopSubdomain: string;
  }>;
  onMenuClick: () => void;
  onShopChange: (shopIndex: number) => void;
  onLogout: (shopIndex: number) => void;
}

const Header: React.FC<HeaderProps> = ({
  userIndex,
  shops,
  onMenuClick,
  onShopChange,
  onLogout,
}) => {
  const { t } = useTranslation();
  const sessions = shops.map((shop, index) => {
    if (index !== userIndex) {
      return (
        <Box key={index}>
          <Divider />
          <Flex
            justifyContent="center"
            margin="auto"
            onClick={() => {
              onShopChange(index);
            }}
          >
            <UserID
              variant="compact"
              name={shop.userFullName}
              shopSubdomain={shop.shopSubdomain}
            />
          </Flex>
        </Box>
      );
    } else {
      return null;
    }
  });

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      height="65px"
      borderBottom="1px solid #dadada"
      padding={["10px 10px", "10px 10px ", "10px 20px"]}
    >
      <Box display={["none", "none", "block"]}>
        <Image width="110px" src={Logo} />
      </Box>
      <Box display={["block", "block", "none"]}>
        <IconButton
          aria-label="menu-button"
          icon={<GiHamburgerMenu />}
          onClick={() => {
            onMenuClick();
          }}
        />
      </Box>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Box role="button">
            <UserID
              name={shops[userIndex].userFullName}
              shopSubdomain={shops[userIndex].shopSubdomain}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent maxWidth="300px">
          <PopoverBody>
            <Box textAlign="center">
              <Avatar
                marginY="0.5rem"
                name={shops[userIndex].userFullName}
                size="lg"
              />
              <Box marginTop="0.5rem" marginBottom="1rem">
                <Box
                  textTransform="capitalize"
                  fontWeight="500"
                  fontSize="1rem"
                  lineHeight="1.1rem"
                >
                  {shops[userIndex].userFullName}
                </Box>
                <Box
                  textTransform="uppercase"
                  fontWeight="900"
                  fontSize="1rem"
                  lineHeight="1.1rem"
                >
                  {shops[userIndex].shopSubdomain}
                </Box>
              </Box>
            </Box>
            {shops.length > 1 && <>{sessions}</>}
            <Box>
              <Divider />
              <Flex
                paddingTop="1rem"
                paddingBottom="0.5rem"
                justifyContent="center"
              >
                <Button
                  onClick={() => {
                    onLogout(userIndex);
                  }}
                >
                  {t("header.logout")}
                </Button>
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default Header;
