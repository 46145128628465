import React from "react";
import { Redirect, useParams } from "react-router-dom";

import axios from "axios";

export const Auth = () => {
  axios
    .get(`/api/session/auth/${window.location.search}`)
    .then((response) => {
      // window.location.replace("/");
    })
    .catch((error) => {
      // window.location.replace("/install");
    });

  return null;
};

export const UserIndexRedirect: React.FC<{ to: string }> = ({ to }) => {
  const { userIndex } = useParams<{ userIndex: string }>();
  return <Redirect to={`/dashboard/${userIndex}${to}`} />;
};
