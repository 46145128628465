import React from "react";
import { useTranslation } from "react-i18next";

import Dashboard from "containers/Dashboard";
import PriceTable from "containers/PriceTable";
import MySubscription from "containers/MySubscription";
import Container from "components/Container";
import { Box } from "@chakra-ui/react";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const { t } = useTranslation();
  return (
    <Dashboard>
      <Container title={t("pages.settings.title", "Settings")}>
        <Box marginBottom="2rem">
          <MySubscription />
        </Box>
        <PriceTable />
      </Container>
    </Dashboard>
  );
};

export default Settings;
