import React from "react";

import Logo from "images/Logo.png";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { usePushPath } from "moship";

interface WelcomeProps {
  onClick: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const pushPath = usePushPath();
  return (
    <>
      <Center marginBottom="1.5rem">
        <Image src={Logo} />
      </Center>
      <Box>
        <Heading size="lg" textAlign="center">
          {t("pages.onboard.thank-you", "Thank you for installing Moshipments")}
        </Heading>
        <Text textAlign="center" fontSize="1.2rem" marginBottom="1.5rem">
          {t(
            "pages.onboard.description",
            "Now we will guide you through the configuration of your app"
          )}
        </Text>
        <Heading size="md" marginY={"0.5rem"} textAlign={"center"}>
          {t("pages.onboard.go-to-shipping-prices-title")}
        </Heading>
        <Button
          marginBottom={"1rem"}
          width={"100%"}
          colorScheme={"teal"}
          onClick={() => {
            pushPath("/carrier-calculated-shipping");
          }}
        >
          {t("pages.onboard.go-to-shipping-prices-button")}
        </Button>
        <Divider marginY={"0.5rem"} />
        <Heading size="md" marginY={"0.5rem"} textAlign={"center"}>
          {t("pages.onboard.continue-with-carriers")}
        </Heading>
        <Text>
          {t("pages.onboard.list-title", "In the next steps you will need")}:
        </Text>
        <Box marginLeft="1rem">
          <ul>
            <li>
              {t(
                "pages.onboard.list1",
                "The dimensions of a package you usually use for your shipments"
              )}
            </li>
            <li>
              {t(
                "pages.onboard.list2",
                "A corporate account with Servientrega"
              )}
            </li>
            <li>
              {t(
                "pages.onboard.list3",
                "The API credentials of your servientrega account"
              )}
            </li>
          </ul>
        </Box>
        <Flex justifyContent="flex-end" marginTop="1.5rem">
          <Button colorScheme="teal" onClick={onClick}>
            {t("Continue")}
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default Welcome;
