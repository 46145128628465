import { IconButton } from "@chakra-ui/react";
import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";

interface GoBackProps {
  onClick?: () => void;
}

const GoBack: React.FC<GoBackProps> = ({ onClick }) => {
  if (!onClick) return null;
  return (
    <IconButton
      aria-label=""
      icon={<MdKeyboardArrowLeft size="30" />}
      marginRight="1rem"
      onClick={onClick}
    />
  );
};

export default GoBack;
