import { Box, Flex, Skeleton } from "@chakra-ui/react";
import React from "react";

const LoadingState: React.FC<{}> = () => {
  return (
    <>
      <Skeleton
        height="4.5rem"
        borderRadius={4}
        marginBottom="1rem"
        endColor="gray.300"
      />
      <Flex>
        <Box
          width="37%"
          paddingRight={5}
          paddingY={5}
          borderRight={[0, 0, "1px solid #f7f7f7"]}
        >
          <Skeleton height="20rem" borderRadius={4} endColor="gray.300" />
        </Box>
        <Box width="63%" paddingLeft={5} paddingY={5} background="white">
          <Skeleton height="20rem" borderRadius={4} endColor="gray.300" />
        </Box>
      </Flex>
    </>
  );
};

export default LoadingState;
