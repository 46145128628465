import { Flex } from "@chakra-ui/layout";
import React from "react";

import { Image, Box, Heading } from "@chakra-ui/react";

interface CarrierCardProps {
  logo: string;
  name: string;
  isAvailable: boolean;
  onClick: () => void;
}

const CarrierCard: React.FC<CarrierCardProps> = ({
  logo,
  name,
  isAvailable,
  onClick,
}) => {
  return (
    <Box position="relative">
      <Flex
        cursor={(isAvailable && "pointer") || ""}
        opacity={(!isAvailable && "0.3") || 1}
        justifyContent="center"
        align="center"
        background="#E2E8F0"
        borderRadius="4px"
        minH="140px"
        onClick={() => {
          if (isAvailable) {
            onClick();
          }
        }}
      >
        {logo && (
          <Box>
            <Image src={logo} maxW="100%" />
          </Box>
        )}
        {!logo && <h2>{name}</h2>}
      </Flex>
      {!isAvailable && (
        <Heading
          size="lg"
          position="absolute"
          bottom="3px"
          width="100%"
          justifyContent="center"
          display="flex"
        >
          Soon
        </Heading>
      )}
    </Box>
  );
};

export default CarrierCard;
