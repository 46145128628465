import React from "react";

import CoordinadoraLogo from "./images/coordinadora-logo.png";
import ServientregaLogo from "./images/servientrega-logo.png";
// import TccLogo from "./images/tcc-logo.png";

import CarrierCard from "components/CarrierCard";

import { SimpleGrid } from "@chakra-ui/react";

import { useAxios, usePushPath } from "moship";

interface CountryCarriersProps {
  country: string;
}

const carriersData = {
  CO: [
    {
      name: "coordinadora",
      logo: CoordinadoraLogo,
      isAvailable: true,
      provider: "coordinadora_colombia",
    },
    {
      name: "servientrega",
      logo: ServientregaLogo,
      isAvailable: true,
      provider: "servientrega_colombia",
    },
    // { name: "tcc", logo: TccLogo, isAvailable: true, provider: "tcc_colombia" },
  ],
};

const CountryCarriers: React.FC<CountryCarriersProps> = ({ country }) => {
  const axios = useAxios();
  const pushPath = usePushPath();
  const carriers = carriersData[country].map((carrier, index) => {
    return (
      <CarrierCard
        key={index}
        logo={carrier.logo}
        name={carrier.name}
        isAvailable={carrier.isAvailable}
        onClick={() => {
          axios
            .post("/api/v1/carriers/", { provider: carrier.provider })
            .then((response) => {
              if (response.status === 201) {
                pushPath(`/carriers/${response.data.id}`);
              }
            })
            .catch((error) => {});
        }}
      />
    );
  });
  return (
    <SimpleGrid spacing={2} columns={2}>
      {carriers}
    </SimpleGrid>
  );
};

export default CountryCarriers;
