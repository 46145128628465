import MoshipContainer from "components/Container";
import Dashboard from "containers/Dashboard";
import { useSWR } from "moship";
import * as React from "react";
import { useTranslation } from "react-i18next";

import ActivateCarrierService from "./ActivateCarrierService";
import ShippingByCity from "./ShippingByCity";

interface CarrierCalculatedShippingProps {}

const CarrierCalculatedShipping: React.FC<
  CarrierCalculatedShippingProps
> = () => {
  const { data: carrierService } = useSWR("/api/v1/shop/carrier-service/");
  const { t } = useTranslation();
  return (
    <Dashboard>
      <MoshipContainer
        title={t(
          "pages.carrier-calculated-shipping.shipping-prices",
          "Shipping prices"
        )}
      >
        <>
          {!carrierService && <ActivateCarrierService />}
          {carrierService && <ShippingByCity />}
        </>
      </MoshipContainer>
    </Dashboard>
  );
};

export default CarrierCalculatedShipping;
