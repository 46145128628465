import * as React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAxios, usePushPath, useSWR } from "moship";

interface ActivateCarrierServiceProps {}

const ActivateCarrierService: React.FC<ActivateCarrierServiceProps> = () => {
  const { t } = useTranslation();
  const { data: shop } = useSWR("/api/v1/shop/");
  const pushPath = usePushPath();
  const axios = useAxios();
  const activatePlanRef = React.useRef();
  const [activatePlanIsOpen, setActivatePlanIsOpen] = React.useState(false);

  if (!shop) return null;

  const createOrUpdateCarrierService = () => {
    axios.get("/api/v1/shop/").then((response) => {
      if (!response.data.active_app_charge) {
        setActivatePlanIsOpen(true);
      } else {
        axios.post("/api/v1/shop/carrier-service/").then((response) => {
          window.location.reload();
        });
      }
    });
  };

  return (
    <>
      <Box>
        <Text marginBottom={"0.5rem"}>
          {t(
            "Para calcular envios por ciudad es necesario tener la funcionalidad de “Tarifas de envío calculadas por terceros” en tu tienda de Shopify."
          )}
        </Text>
        <Text marginBottom={"0.5rem"}>
          {t(
            "Esta funcionalidad esta incluida de forma gratuita en tu Shopify cuando"
          )}
        </Text>
        <UnorderedList marginBottom={"1rem"}>
          <ListItem>{t("Pagas tu plan de forma anual.")}</ListItem>
          <ListItem>{t("Tienes el plan “Advanced” o Shopify plus.")}</ListItem>
        </UnorderedList>
        <Text marginBottom={"1rem"}>
          {t(
            "Si no tienes esta funcionalidad activa en tu tienda y quieres utilizar nuestro módulo de calculo de precios de envio debes escribir al chat de soporte de Shopify para que te lo activen por un cargo adicional en tu plan actual."
          )}
        </Text>
        <hr />
        <Text marginY={"1rem"}>
          {t("Current plan")}: <strong>{shop.shopify_plan}</strong>
        </Text>
        <hr />
        <Text marginY={"1rem"}>
          {t(
            "Si tienes activa la funcionalidad de  “Tarifas de envío calculadas por terceros” puedes activar el modulo "
          )}
        </Text>
        <Button colorScheme={"teal"} onClick={createOrUpdateCarrierService}>
          {t("Activate")}
        </Button>
      </Box>
      <AlertDialog
        isOpen={activatePlanIsOpen}
        onClose={() => {
          setActivatePlanIsOpen(false);
        }}
        leastDestructiveRef={activatePlanRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{t("Action required")}</AlertDialogHeader>
          <AlertDialogBody>
            {t("This feature require an active moshipping plan")}
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack>
              <Button
                onClick={() => {
                  setActivatePlanIsOpen(false);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                colorScheme={"teal"}
                onClick={() => {
                  pushPath("/settings");
                }}
              >
                {t("See available plans")}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ActivateCarrierService;
