import React from "react";
import { Box, Text, Avatar } from "@chakra-ui/react";

import CompactUserId from "./CompactUserId";

export interface UserIdProps {
  name: string;
  shopSubdomain: string;
  variant?: "compact" | null;
}

export const UserId: React.FC<UserIdProps> = ({
  name,
  shopSubdomain,
  variant,
}) => {
  if (variant === "compact") {
    return (
      <CompactUserId
        name={name}
        shopSubdomain={shopSubdomain}
        variant={variant}
      />
    );
  }

  return (
    <Box
      display="flex"
      width="fit-content"
      padding="3px 5px 3px 8px"
      borderRadius="5px"
      border="1px solid transparent"
      sx={{
        "&:hover": {
          background: "#F7FAFC",
          cursor: "pointer",
          border: "1px solid #A0AEC0",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="right"
        fontSize="16px"
        lineHeight="18px"
        marginBottom="4px"
      >
        <Text>{name}</Text>
        <Text fontWeight="700" textTransform="uppercase">
          {shopSubdomain}
        </Text>
      </Box>
      <Box>
        <Avatar name={name} width="45px" height="45px" marginLeft="10px" />
      </Box>
    </Box>
  );
};

export default UserId;
