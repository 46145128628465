import * as React from "react";
import { useState } from "react";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useAxios } from "moship";

interface AssignValuesProps {}

const AssignValues: React.FC<AssignValuesProps> = ({}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    carrier_provider: "",
    centro_poblado_codes: "",
  };
  const [values, setValues] = useState(defaultValues);

  const onSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post("/api/v1/carrier-locations/", values)
      .then((response) => {
        setValues(defaultValues);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <VStack>
          <FormControl>
            <FormLabel>{t("Transportadora")}</FormLabel>
            <Select
              required
              onChange={onChange("carrier_provider")}
              value={values.carrier_provider}
            >
              <option value=""></option>
              <option value="coordinadora_colombia">Coordinadora</option>
              <option value="servientrega_colombia">Servientrega</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("Código centro población (Código dane)")}</FormLabel>
            <Textarea
              rows={10}
              placeholder={"Lista códigos dane separados por espacios"}
              onChange={onChange("centro_poblado_codes")}
              value={values.centro_poblado_codes}
              required
            ></Textarea>
          </FormControl>
        </VStack>
        <Flex justifyContent={"flex-end"} marginY={"1rem"}>
          <Button type="submit" isLoading={isLoading}>
            {t("Actualizar")}
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default AssignValues;
