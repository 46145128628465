import * as React from "react";
import { useState, useEffect } from "react";

import { useSWR } from "moship";
import { Table, Tbody, Td, Tr } from "@chakra-ui/react";

interface ValuesEditorProps {}

const ValuesEditor: React.FC<ValuesEditorProps> = ({}) => {
  const { data } = useSWR("/api/v1/carrier-locations/");

  if (!data) return null;

  return (
    <>
      <Table>
        <Tbody>
          {data.data.map((carrierLocation, index) => {
            return (
              <Tr key={index}>
                <Td>{carrierLocation.centro_poblado_code}</Td>
                <Td>{carrierLocation.carrier_provider}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default ValuesEditor;
