import React from "react";
import { useTranslation } from "react-i18next";
import { useSWR } from "moship";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

interface MySubscriptionProps {}

const MySubscription: React.FC<MySubscriptionProps> = () => {
  const {
    data: subscription,
    isLoading,
    error,
  } = useSWR("/api/v1/subscription/");
  const { t } = useTranslation();

  if (isLoading) return null;
  if (error) return null;

  const remainingFreeShipments = subscription.remaining_free_shipments;
  let remainingFreeShipmentsRender = null;
  if (remainingFreeShipments === -1) {
    remainingFreeShipmentsRender = (
      <Box padding="0.5rem 1rem" background="blackAlpha.800" color="white">
        <Heading size="md">
          {t(
            "containers.my-subscription.unlimited",
            "You have unlimited free shipments"
          )}
        </Heading>
      </Box>
    );
  } else if (remainingFreeShipments > 0) {
    remainingFreeShipmentsRender = (
      <Box padding="0.5rem 1rem" background="blackAlpha.800" color="white">
        <Heading size="md">
          {t(
            "containers.my-subscription.remaining-free-shipments",
            "You have {{ remainingFreeShipments }} free shipments left",
            { remainingFreeShipments }
          )}
        </Heading>
      </Box>
    );
  } else if (remainingFreeShipments === 0 && !subscription.plan) {
    remainingFreeShipmentsRender = (
      <Box padding="0.5rem 1rem" background="blackAlpha.800" color="white">
        <Heading size="md">
          {t(
            "containers.my-subscription.no-shipments-no-plan",
            "You have 0 free shipments left, Please activate a plan to continue using your app"
          )}
        </Heading>
      </Box>
    );
  }
  let subscriptionRender = null;
  if (subscription.plan) {
    const nextBill = subscription.plan.next_billing_on;
    const usedShipments = subscription.plan.period_shipments_count;
    const purchasedShipments = subscription.plan.monthly_shipments;
    const extraShipmentPrice = subscription.plan.price_per_extra_shipment;
    subscriptionRender = (
      <Box padding="1rem">
        <Flex alignItems="center" marginBottom="1rem">
          <Box
            borderRight="1px solid"
            borderColor="blackAlpha.700"
            paddingRight="1rem"
          >
            <Heading lineHeight="2.9rem">{subscription.plan.name}</Heading>
            <Text
              color="blackAlpha.600"
              fontSize="0.9rem"
              lineHeight="0.7rem"
              padding="0 0 0 3px"
            >
              {t(
                "containers.my-subscription.your-subscription",
                "your subscription"
              )}
            </Text>
          </Box>
          <Flex marginLeft="1rem" alignItems="center">
            <Heading marginRight="5px">${subscription.plan.price}</Heading>
            <Text>/{t("month")}</Text>
            <Text marginLeft="1rem" fontWeight="black">
              {t("containers.my-subscription.renews", "Renews {{ nextBill }}", {
                nextBill,
              })}
            </Text>
          </Flex>
        </Flex>
        <Box>
          <Text fontWeight="bold">
            {t("containers.my-subscription.usage-title", "Usage")}:
          </Text>
          <Text>
            {t(
              "containers.my-subscription.usage",
              "{{ usedShipments }} shipments of {{ purchasedShipments }}",
              { usedShipments, purchasedShipments }
            )}
          </Text>

          <Text>
            {t(
              "containers.my-subscription.extra-shipments",
              // eslint-disable-next-line
              "${{ extraShipmentPrice }} per extra shipment",
              { extraShipmentPrice }
            )}
          </Text>
        </Box>
      </Box>
    );
  }
  return (
    <Box border="1px solid" borderColor="blackAlpha.800">
      {remainingFreeShipmentsRender}
      {subscriptionRender}
    </Box>
  );
};

export default MySubscription;
