import React from "react";

import { Tag } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

interface FulfillmentTagProps {
  state: string;
}

const FulfillmentTag: React.FC<FulfillmentTagProps> = ({ state }) => {
  const { t } = useTranslation();

  const unfulfilled = t([
    "components.fulfillment-tag.unfulfilled",
    "Unfulfilled",
  ]);
  const fulfilled = t(["components.fulfillment-tag.fulfilled", "Fulfilled"]);
  const partially_fulfilled = t([
    "components.fulfillment-tag.partial",
    "Partially fulfilled",
  ]);

  switch (state) {
    case "unfulfilled":
      return <Tag colorScheme="orange">{unfulfilled}</Tag>;
    case "":
      return <Tag colorScheme="orange">{unfulfilled}</Tag>;
    case "partial":
      return <Tag colorScheme="orange">{partially_fulfilled}</Tag>;
    case "partially_fulfilled":
      return <Tag colorScheme="orange">{partially_fulfilled}</Tag>;
    case "open":
      return <Tag colorScheme="orange">{unfulfilled}</Tag>;
    case "success":
      return <Tag colorScheme="teal">{fulfilled}</Tag>;
    case "fulfilled":
      return <Tag colorScheme="teal">{fulfilled}</Tag>;
    case "cancelled":
      return <Tag>{t("Canceled")}</Tag>;
    default:
      return null;
  }
};

export default FulfillmentTag;
