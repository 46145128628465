import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import { useAxios, usePushPath, useSWR } from "moship";
import * as React from "react";

import { useTranslation } from "react-i18next";

interface ShippingByCityProps {}

const ShippingByCity: React.FC<ShippingByCityProps> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { data: origins } = useSWR("/api/v1/shop/shipping-by-city-origins/");
  const pushPath = usePushPath();

  if (!origins) return null;

  const deactivateCarrierService = () => {
    axios.delete("/api/v1/shop/carrier-service").then((response) => {
      window.location.reload();
    });
  };

  return (
    <Box>
      <Text marginBottom={"1rem"}>
        {t("pages.shipping-by-city.description")}:
      </Text>
      {origins?.length === 0 && (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          w="100%"
        >
          <AlertIcon />
          <AlertTitle>
            {t(
              "pages.shipping-by-city.no-origins-title",
              "A location in Colombia is required"
            )}
          </AlertTitle>
          <AlertDescription>
            {t(
              "pages.shipping-by-city.no-origins-description",
              "You need to add at least one location in Colombia to use this service, check the address of your Colombian location in the settings of your store."
            )}
          </AlertDescription>
        </Alert>
      )}
      <SimpleGrid columns={2} spacing={"1rem"}>
        {origins.map((origin, index) => {
          return (
            <Box key={index}>
              <Button
                colorScheme="teal"
                width={"100%"}
                onClick={() => {
                  pushPath(
                    `/carrier-calculated-shipping/shipping-by-city/${origin.id}/`
                  );
                }}
              >
                {t("pages.shipping-by-city.origin-button-text", {
                  city: origin.city.name,
                })}
              </Button>
            </Box>
          );
        })}
      </SimpleGrid>
      <Divider marginY={"1rem"} />
      <Box>
        <Text marginY={"0.5rem"}>
          {t("pages.shipping-by-city.deactivate-carrier-description")}
        </Text>
        <Button onClick={deactivateCarrierService}>
          {t("pages.shipping-by-city.deactivate-button-text")}
        </Button>
      </Box>
    </Box>
  );
};

export default ShippingByCity;
