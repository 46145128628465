import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

import Dashboard from "containers/Dashboard";

import Container from "components/Container";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface OrderProps {}

const Order: React.FC<OrderProps> = () => {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const history = useHistory();
  const { search } = useLocation();

  return (
    <Dashboard>
      <Container title={t("Order")}>
        {orderId}
        <Button
          onClick={() => {
            let currentSearch = new URLSearchParams(search);
            let from = decodeURIComponent(currentSearch.get("from"));
            history.push(from);
          }}
        >
          Fulfill another order
        </Button>
      </Container>
    </Dashboard>
  );
};

export default Order;
