import useSWR from "swr";
import axios from "axios";

import { useLocation, useHistory, useParams } from "react-router-dom";

export const fetcher = (path: string) => {
  return axios.get(`${path}`).then((res) => res.data);
};

export const useShops = () => {
  const { data, error } = useSWR(`/api/session/shops/`, {
    fetcher: fetcher,
    // revalidateOnFocus: false,
    // revalidateOnMount: true,
    // revalidateOnReconnect: false,
    // refreshInterval: 0,
  });

  return {
    shops: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCurrentShop = () => {
  const { shops, isError, isLoading } = useShops();
  const { userIndex } = useParams<{ userIndex: string }>();
  if (isError || isLoading) return null;
  return shops[parseInt(userIndex)];
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useOrderSearch = () => {
  const { search } = useLocation();
  var searchParams = new URLSearchParams(search);
  var orderSearchParams = new URLSearchParams("");

  const orderParams = [
    "fulfillment_status",
    "search",
    "unfulfilled",
    "not_cancelled",
    "page",
  ];

  let values = searchParams.keys();
  let value = values.next();
  while (!value.done) {
    let key = value.value;
    if (orderParams.includes(key)) {
      orderSearchParams.set(key, searchParams.get(key));
    }
    value = values.next();
  }

  return orderSearchParams.toString();
};

export const usePushPath = () => {
  const history = useHistory();
  const { userIndex } = useParams<{ userIndex: string }>();

  return (path) => {
    const pathWithIndex = `/dashboard/${userIndex}${path}`;
    history.push(pathWithIndex);
  };
};
