import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Container as ChakraContainer,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";

import Dashboard from "containers/Dashboard";
import Pagination from "containers/Pagination";

import Container from "components/Container";
import EmptyState from "components/EmptyState";

import OrderDrawer from "./OrderDrawer";
import OrderRow from "./OrderRow";

import { useSWR, useOrderSearch } from "moship";
import { BiSearch } from "react-icons/bi";
import { Redirect } from "react-router";
import CreateShipment from "containers/CreateShipment";

import { useTranslation } from "react-i18next";

interface OrderProps {}

const Orders: React.FC<OrderProps> = () => {
  const { t } = useTranslation();
  const selectedOrderRef = useRef(null);
  const [openedOrderId, setOpenedOrderId] = useState(null);
  const [openedOrderName, setopenedOrderName] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const ordersSearch = useOrderSearch();
  const [searchForm, setSearchForm] = useState("");

  const {
    data: orders,
    isLoading,
    error,
    mutate,
  } = useSWR(`/api/v1/orders/?${ordersSearch}`);

  useEffect(() => {
    if (selectedOrderRef.current)
      selectedOrderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    return () => {};
  }, [selectedOrderRef]);

  useEffect(() => {
    var searchParams = new URLSearchParams(search);
    setSearchForm(searchParams.get("search") || "");

    return () => {};
  }, [setSearchForm, setOpenedOrderId, search]);

  useEffect(() => {
    var searchParams = new URLSearchParams(search);
    const selected = searchParams.get("selected");
    const fulfillment_order = searchParams.get("fulfillment_order");
    if (selected && !fulfillment_order) {
      setOpenedOrderId(selected);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  if (isLoading) return null;

  if (error && error.response.status === 401)
    return <Redirect to="/install/" />;

  if (error) return <h1>error</h1>;

  var searchParams = new URLSearchParams(search);
  const selectedId = searchParams.get("selected");

  const onSearchSubmit = () => {
    searchParams.set("search", searchForm);
    history.replace({
      search: `?${searchParams.toString()}`,
    });
  };

  const onOrderClick = (event, orderId) => {
    event.target.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "nearest",
    });

    let currentSearch = new URLSearchParams(search);
    currentSearch.set("selected", orderId);

    history.replace({
      search: `?${currentSearch.toString()}`,
    });

    for (let index = 0; index < orders.results.length; index++) {
      const order = orders.results[index];

      if (order.id === orderId) {
        setopenedOrderName(order.order_name);
        break;
      }
    }
    setOpenedOrderId(orderId);
  };

  const ordersRow = orders.results.map((order: any, index: number) => {
    const date = new Date(order.created_at);
    const shipmentsArray = order.shipments.map((shipment) => {
      return {};
    });
    const cancelled = order.cancelled_at ? true : false;
    const status = order.fulfillment_status;
    const fulfillmentStatus = status ? status : "unfulfilled";
    const selected = parseInt(selectedId) === order.id ? true : false;

    return (
      <OrderRow
        ref={selectedOrderRef}
        selected={selected}
        id={order.id}
        isCancelled={cancelled}
        key={index}
        orderName={order.order_name}
        createdAt={date}
        destination={order.shipping}
        shipments={shipmentsArray}
        onClick={onOrderClick}
        fulfillmentStatus={fulfillmentStatus}
        paymentGateways={order.payment_gateways}
      />
    );
  });

  const onModalClose = () => {
    setOpenedOrderId(null);
    searchParams.delete("fulfillment_order");
    history.push({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <>
      <Dashboard>
        <Container title={t("pages.orders.title", "Orders")}>
          <Flex marginBottom="1rem">
            <InputGroup marginRight="0.5rem">
              <InputLeftAddon>
                <BiSearch />
              </InputLeftAddon>
              <Input
                type="number"
                placeholder={t("pages.orders.search.order-number")}
                onChange={(e) => {
                  setSearchForm(e.target.value);
                }}
                value={searchForm}
              />
            </InputGroup>
            <Button colorScheme="teal" onClick={onSearchSubmit}>
              {t("pages.orders.search.search", "Search")}
            </Button>
          </Flex>
          {ordersRow.length !== 0 && (
            <>
              <Box maxWidth="100%" overflowX="scroll">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Td>
                        <strong>{t("pages.orders.table-title.order")}</strong>
                      </Td>
                      <Td>
                        <strong>{t("pages.orders.table-title.date")}</strong>
                      </Td>
                      <Td textAlign="center">
                        <strong>
                          {t("pages.orders.table-title.destination")}
                        </strong>
                      </Td>
                      <Td>
                        <strong>
                          {t("pages.orders.table-title.payment-methods")}
                        </strong>
                      </Td>
                      <Td>
                        <strong>
                          {t("pages.orders.table-title.fulfillment-status")}
                        </strong>
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody>{ordersRow}</Tbody>
                </Table>
              </Box>
              <Flex justifyContent="center" marginY="1rem">
                <Pagination next={orders.next} previous={orders.previous} />
              </Flex>
            </>
          )}

          {ordersRow.length === 0 && (
            <EmptyState
              title={t("pages.orders.empty-title")}
              description={t("pages.orders.empty-description")}
            />
          )}
        </Container>
        <OrderDrawer
          orderId={openedOrderId}
          orderName={openedOrderName}
          onDrawerClose={() => {
            setOpenedOrderId(null);
          }}
        />
        {searchParams.get("fulfillment_order") !== null && (
          <Modal
            isOpen={searchParams.get("fulfillment_order") !== null}
            onClose={() => {
              onModalClose();
            }}
            size="full"
          >
            <ModalOverlay />
            <ModalContent borderRadius={0}>
              <ChakraContainer maxWidth="container.xl">
                <ModalCloseButton />
                <ModalHeader>
                  {t("pages.orders.create-shipment-title")}
                </ModalHeader>
                <ModalBody>
                  <CreateShipment
                    fulfillmentOrderId={searchParams.get("fulfillment_order")}
                    onFulfillmentCreated={mutate}
                    onFulfillmentCancelled={mutate}
                  />
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ChakraContainer>
            </ModalContent>
          </Modal>
        )}
      </Dashboard>
    </>
  );
};

export default Orders;
