import React from "react";

import { NoUser } from "components";
import { Auth, UserIndexRedirect } from "moship/components";

import Install from "./pages/Install";
import Orders from "./pages/Orders";
import Carriers from "./pages/Carriers";
import CarriersLocations from "./pages/CarriersLocations";
import Carrier from "./pages/Carrier";
import NotFound from "./pages/NotFound";
import Packages from "./pages/Packages";
import Locations from "./pages/Locations";
import Order from "./pages/Order";
import FulfillmentOrder from "./pages/FulfillmentOrder";
import Onboard from "./pages/Onboard";
import CarrierCalculatedShipping from "./pages/CarrierCalculatedShipping";
import ShippingByCity from "./pages/ShippingByCity";
import Settings from "./pages/Settings";
import Help from "./pages/Help";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export const AppRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/install">
          <Install />
        </Route>
        <Route
          path="/dashboard/:userIndex/orders/all"
          render={() => {
            return <Orders />;
          }}
        />
        <Route path="/dashboard/:userIndex/orders/unfulfilled">
          <Orders />
        </Route>
        <Route path="/dashboard/:userIndex/orders/partial">
          <Orders />
        </Route>
        <Route path="/dashboard/:userIndex/orders/fulfilled">
          <Orders />
        </Route>
        <Route exact path="/dashboard/:userIndex/orders/:orderId">
          <Order />
        </Route>
        <Route
          exact
          path="/dashboard/:userIndex/orders/:orderId/fulfillment-order/:fulfillmentOrderId"
        >
          <FulfillmentOrder />
        </Route>
        <Route exact path="/dashboard/:userIndex/carriers">
          <Carriers />
        </Route>
        <Route exact path="/dashboard/:userIndex/carriers-locations">
          <CarriersLocations />
        </Route>
        <Route exact path="/dashboard/:userIndex/carriers/:carrierId">
          <Carrier />
        </Route>
        <Route path="/dashboard/:userIndex/packages">
          <Packages />
        </Route>
        <Route path="/dashboard/:userIndex/locations">
          <Locations />
        </Route>
        <Route path="/dashboard/:userIndex/orders">
          <UserIndexRedirect to="/orders/all" />
        </Route>
        <Route path="/dashboard/:userIndex/onboard">
          <Onboard />
        </Route>
        <Route path="/dashboard/:userIndex/carrier-calculated-shipping/shipping-by-city/:originId/">
          <ShippingByCity />
        </Route>
        <Route path="/dashboard/:userIndex/carrier-calculated-shipping">
          <CarrierCalculatedShipping />
        </Route>
        <Route path="/dashboard/:userIndex/settings">
          <Settings />
        </Route>
        <Route path="/dashboard/:userIndex/help">
          <Help />
        </Route>
        <Route exact path="/dashboard/:userIndex/">
          <UserIndexRedirect to="/orders/all" />
        </Route>
        <Route exact path="/dashboard/">
          <NoUser />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};
