import React from "react";
import { Box, Button, Divider, Flex, HStack } from "@chakra-ui/react";
import DeleteButton from "components/DeleteButton";

interface SaveChangesProps {
  isLoading?: boolean;
  formChanged?: boolean;
  onDelete?: () => void;
  deleteAlertTitle?: string;
  deleteAlertDescription?: string;
}

const SaveChanges: React.FC<SaveChangesProps> = ({
  isLoading,
  formChanged,
  onDelete,
  deleteAlertTitle,
  deleteAlertDescription,
}) => {
  return (
    <>
      {formChanged && (
        <Box
          position="fixed"
          top="0"
          right="0"
          background="#202123"
          width="100%"
          padding="11px"
          height="64px"
        >
          <Flex justify="flex-end">
            <Button
              colorScheme="teal"
              variant="solid"
              type="submit"
              isLoading={isLoading}
            >
              Save changes
            </Button>
          </Flex>
        </Box>
      )}
      <Divider marginY="2rem" />
      <Flex justifyContent="flex-end">
        <HStack>
          {onDelete && (
            <>
              <DeleteButton
                buttonText="Delete"
                alertTitle={deleteAlertTitle ? deleteAlertTitle : ""}
                alertDescription={
                  deleteAlertDescription ? deleteAlertDescription : ""
                }
                cancelButtonText="cancel"
                confirmButtonText="Delete"
                onDelete={onDelete}
              />
            </>
          )}
          <Button type="submit" isLoading={isLoading} disabled={!formChanged}>
            Save
          </Button>
        </HStack>
      </Flex>
    </>
  );
};

export default SaveChanges;
