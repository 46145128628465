import { useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export const useAxios = () => {
  let { userIndex } = useParams<{ userIndex: string }>();

  return useMemo(() => {
    const instance = axios.create();
    if (userIndex) {
      instance.interceptors.request.use(function (config) {
        config.headers["moship-user-index"] = userIndex;
        return config;
      });
    }
    return instance;
  }, [userIndex]);
};
