import React, { useState, useEffect, useMemo } from "react";

import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface PackagesProps {
  packages: Array<{
    id: number;
    name: string;
    type: string;
    height: number;
    width: number;
    length: number;
    unit_of_length: string;
    weight: number;
    unit_of_mass: string;
  }>;
  onChange: (packageData: any) => void;
  preselectedPackage?: number;
}

const Packages: React.FC<PackagesProps> = ({
  packages,
  onChange,
  preselectedPackage,
}) => {
  const { t } = useTranslation();
  const initialPackage = preselectedPackage ? String(preselectedPackage) : "";

  const [selectedPackage, setSelectedPackage] = useState(initialPackage);
  const initialValues = useMemo(() => {
    return {
      height: 0,
      id: 0,
      length: 0,
      name: "custom",
      type: "bag",
      unit_of_length: "cm",
      unit_of_mass: "Kg",
      weight: 0,
      width: 0,
    };
  }, []);
  const [packageValues, setPackageValues] = useState(initialValues);

  useEffect(() => {
    if (selectedPackage === "0") {
      setPackageValues(initialValues);
    }

    for (let index = 0; index < packages.length; index++) {
      const element = packages[index];

      if (element.id === parseInt(selectedPackage)) {
        setPackageValues(element);
        break;
      }
    }

    return () => {};
  }, [selectedPackage, packages, initialValues]);

  useEffect(() => {
    onChange(packageValues);
    return () => {};
  }, [packageValues, onChange]);

  const onChangePackage = (event) => {
    setSelectedPackage(event.target.value);
  };

  const onChangePackageValue = (name) => (event) => {
    setPackageValues({
      ...packageValues,
      [name]: event.target.value,
    });
  };

  const packagesOptions = packages.map((packageData, index) => {
    if (index === 0 && selectedPackage === "") {
      setSelectedPackage(String(packageData.id));
    }

    return (
      <option value={packageData.id} key={index}>
        {packageData.name}
      </option>
    );
  });

  return (
    <Flex marginY="0.5rem">
      <FormControl marginBottom="0.5rem" marginRight="1rem">
        <FormLabel>
          {t(["containers.create-shipment.packages.package", "Package"])}
        </FormLabel>
        <Select onChange={onChangePackage} value={selectedPackage}>
          {packagesOptions}
          <option value="0">
            {t(["containers.create-shipment.packages.custom", "Custom"])}
          </option>
        </Select>
      </FormControl>
      <Flex justifyContent="space-between">
        <FormControl width="65%">
          <FormLabel>
            {t([
              "containers.create-shipment.packages.dimensions",
              "Dimensions",
            ])}
          </FormLabel>
          <HStack>
            <Input
              type="number"
              paddingX={2}
              textAlign="center"
              value={packageValues.height}
              onChange={onChangePackageValue("height")}
              disabled={packageValues.id !== 0}
            />
            <Input
              type="number"
              paddingX={2}
              textAlign="center"
              value={packageValues.length}
              onChange={onChangePackageValue("length")}
              disabled={packageValues.id !== 0}
            />
            <Input
              type="number"
              paddingX={2}
              textAlign="center"
              value={packageValues.width}
              onChange={onChangePackageValue("width")}
              disabled={packageValues.id !== 0}
            />
            <Select
              minW="70px"
              value={packageValues.unit_of_length}
              onChange={onChangePackageValue("unit_of_length")}
              disabled={packageValues.id !== 0}
            >
              <option>cm</option>
              <option>in</option>
            </Select>
          </HStack>
        </FormControl>
        <FormControl width="33%">
          <FormLabel>
            {t(["containers.create-shipment.packages.weight", "Weight"])}
          </FormLabel>
          <HStack>
            <Input
              type="number"
              paddingX={2}
              textAlign="center"
              value={packageValues.weight}
              onChange={onChangePackageValue("weight")}
            />
            <Select
              minW="70px"
              value={packageValues.unit_of_mass}
              onChange={onChangePackageValue("unit_of_mass")}
              disabled={packageValues.id !== 0}
            >
              <option>kg</option>
              <option>lb</option>
            </Select>
          </HStack>
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default Packages;
