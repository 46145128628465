import { Box, Button, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Background from "./images/background2.jpg";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const { t } = useTranslation();
  return (
    <Box backgroundImage={`url(${Background})`} backgroundSize="cover">
      <Container background="#fff">
        <Flex minHeight="100vh" alignItems="center" justifyContent="center">
          <Box textAlign="center">
            <Heading fontSize="150px">404</Heading>
            <Text fontSize="30px">{t("Page not found")}</Text>
            <Text>
              {t(
                "Looks like the page you're looking for no longer exist, click in the button bellow to go somewhere"
              )}
            </Text>
            <Button as="a" href="/" marginY="2rem" colorScheme="teal">
              {t("Go to home")}
            </Button>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default NotFound;
