import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Flag from "react-world-flags";

import { useTranslation } from "react-i18next";

import { FaPhone } from "react-icons/fa";

interface OrderInfoProps {
  LocationName: string;
  LocationCountryCode: string;
  note?: string;
  shippingAddress?: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    country_code: string;
    name: string;
    company: string;
    phone: string;
    province: string;
    zip: string;
  };
}

const OrderInfo: React.FC<OrderInfoProps> = ({
  LocationName,
  note,
  LocationCountryCode,
  shippingAddress,
}) => {
  const { t } = useTranslation();
  let shippingAddressRender = null;
  if (shippingAddress) {
    shippingAddressRender = (
      <Box marginBottom="1rem">
        <Heading size="md" marginBottom="0.5rem">
          {t([
            "containers.create-shipment.order-info.shipping-address",
            "Shipping address",
          ])}
        </Heading>
        <Box>
          <Text>
            {shippingAddress.address1} {shippingAddress.address2}{" "}
            {shippingAddress.zip}
          </Text>
          <Flex textTransform="capitalize" alignItems="center">
            <Text marginRight="0.5rem">
              {shippingAddress.city} {shippingAddress.province}{" "}
            </Text>
            <Box>
              <Flag
                code={shippingAddress.country_code}
                style={{ maxWidth: "30px", height: "20px" }}
              />
            </Box>
          </Flex>
          <Box>
            <Text textTransform="capitalize">
              {shippingAddress.name} {shippingAddress.company}
            </Text>
            <Flex align="center">
              <FaPhone size={12} />{" "}
              <Text marginLeft="0.5rem">{shippingAddress.phone}</Text>
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Box marginBottom="1rem">
        <Heading size="md" marginBottom="0.25rem">
          {t(["containers.create-shipment.order-info.location", "Location"])}
        </Heading>
        <Flex alignItems="center">
          <Text marginRight="0.5rem">{LocationName}</Text>
          <Flag
            code={LocationCountryCode}
            height="18px"
            style={{ maxWidth: "30px" }}
          />
        </Flex>
      </Box>
      {note && (
        <Box marginBottom="1rem">
          <Heading size="md" marginBottom="0.25rem">
            {t(["containers.create-shipment.order-info.notes", "Notes"])}
          </Heading>
          <Flex alignItems="center">
            <Text marginRight="0.5rem">{note}</Text>
          </Flex>
        </Box>
      )}
      {shippingAddressRender && shippingAddressRender}
    </>
  );
};

export default OrderInfo;
