import { Td, Tr, Box, Tag, Flex } from "@chakra-ui/react";
import React from "react";
import FulfillmentTag from "components/FulfillmentTag";

interface OrderRowProps {
  id: number;
  orderName: string;
  createdAt: Date;
  destination: {
    country: string;
    province: string;
    city: string;
  } | null;
  paymentGateways: Array<{ name: string }>;
  shipments: Array<any>;
  onClick: (event: any, id: number) => void;
  isCancelled?: boolean;
  fulfillmentStatus?: string;
  selected?: boolean;
}

export const OrderRow = React.forwardRef<any, OrderRowProps>(
  (
    {
      id,
      orderName,
      createdAt,
      destination,
      paymentGateways,
      onClick,
      isCancelled,
      fulfillmentStatus,
      selected,
    },
    ref
  ) => {
    const paymentGatewaysRender = paymentGateways.map((gateway, index) => {
      return (
        <Tag whiteSpace="nowrap" key={index} margin="0 3px 3px 0">
          {gateway.name}
        </Tag>
      );
    });

    return (
      <Tr
        ref={selected ? ref : null}
        opacity={isCancelled && "0.5"}
        cursor={!isCancelled && "pointer"}
        _hover={{ background: "#e2e8f076" }}
        className={selected && "selected"}
        onClick={(event) => {
          if (!isCancelled) {
            onClick(event, id);
          }
        }}
        sx={{
          ".unfulfilled": {
            background: "#ffea8a",
          },
          ".partial": {
            background: "#ffd79d",
            minW: "120px",
          },
          "&.selected": {
            background: "#f3f3f376",
            "&:hover": { background: "#e2e8f076" },
          },
        }}
      >
        <Td>
          <Flex minHeight="55px" alignItems="center">
            {orderName}
          </Flex>
        </Td>
        <Td>
          <Box minWidth="140px">
            {createdAt.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Box>
        </Td>
        <Td minW="fit-content">
          {destination && (
            <Box textAlign="center" textTransform="capitalize">
              <Box>
                {destination.city && destination.city.toLocaleLowerCase()}{" "}
                {destination.province &&
                  destination.province.toLocaleLowerCase()}
              </Box>
              <Tag>
                {destination.country && destination.country.toLocaleLowerCase()}
              </Tag>
            </Box>
          )}
        </Td>
        <Td>{paymentGatewaysRender}</Td>
        <Td>
          <FulfillmentTag state={fulfillmentStatus} />
        </Td>
      </Tr>
    );
  }
);

export default OrderRow;
