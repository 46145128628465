import React from "react";
import { HStack, Table, Tag, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface CarriersTableProps {
  carriers: Array<{
    name: string;
    id: number;
    provider: string;
    activeLocations: Array<string>;
  }>;
  onCarrierClick: (carrierId: number) => void;
}

const CarriersTable: React.FC<CarriersTableProps> = ({
  carriers,
  onCarrierClick,
}) => {
  const { t } = useTranslation();
  if (carriers.length === 0) return null;
  const carrierRows = carriers.map((carrier, index) => {
    const locations = carrier.activeLocations.map((location, index) => {
      return <Tag key={index}>{location}</Tag>;
    });

    return (
      <Tr
        key={index}
        cursor="pointer"
        onClick={() => {
          onCarrierClick(carrier.id);
        }}
      >
        <Td>{carrier.name}</Td>
        <Td>{carrier.provider}</Td>
        <Td>
          <HStack>{locations}</HStack>
        </Td>
      </Tr>
    );
  });
  return (
    <Table>
      <Thead>
        <Tr fontWeight="bold">
          <Td>{t("pages.carriers.table-title-carrier")}</Td>
          <Td>{t("pages.carriers.table-title-provider")}</Td>
          <Td>{t("pages.carriers.table-title-locations")}</Td>
        </Tr>
      </Thead>
      <Tbody>{carrierRows}</Tbody>
    </Table>
  );
};

export default CarriersTable;
