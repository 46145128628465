import React from "react";

import { useSWR } from "moship";
import { useTranslation } from "react-i18next";

import Dashboard from "containers/Dashboard";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

interface LocationsProps {}

const Locations: React.FC<LocationsProps> = () => {
  const { t } = useTranslation();
  const { data: locations, error, isLoading } = useSWR("/api/v1/locations/");
  const {
    data: shop,
    error: shopError,
    isLoading: shopIsLoading,
  } = useSWR("/api/v1/shop/");

  if (isLoading || error || shopError || shopIsLoading) return null;

  const locationsRender = locations.map((location, index) => {
    const address = location.address;
    return (
      <Box key={index} padding="15px" background="#e2e8f0" borderRadius="4px">
        <Text>
          <strong>{t("pages.locations.name", "Name")}:</strong> {location.name}
        </Text>
        <Text>
          <strong>{t("pages.locations.address", "Address")}:</strong>{" "}
          {address.address1} {address.address2}
        </Text>
        <Text>
          <strong>{t("pages.locations.location", "Location")}:</strong>{" "}
          {address.city} - {address.province}
        </Text>
        <Flex marginTop="1rem">
          <Button
            as="a"
            target="_blank"
            colorScheme="teal"
            href={`https://${shop.subdomain}.myshopify.com/admin/settings/locations/${location.shopify_rid}`}
          >
            {t("pages.locations.edit", "Edit")}
          </Button>
        </Flex>
      </Box>
    );
  });

  return (
    <Dashboard>
      <Container maxW="container.xl">
        <Box paddingX="1.2rem">
          <Heading as="h1" size="lg" marginY="1.2rem">
            {t("pages.locations.title", "Locations")}
          </Heading>
          <Text marginBottom="1.5rem">
            {t("pages.locations.description", "")}
          </Text>
          <Box>
            <SimpleGrid columns={2} spacing={5}>
              {locationsRender}
            </SimpleGrid>
          </Box>
        </Box>
      </Container>
    </Dashboard>
  );
};

export default Locations;
