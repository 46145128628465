import * as React from "react";

import {
  BiPackage,
  BiMap,
  BiCog,
  BiHelpCircle,
  BiPaperPlane,
} from "react-icons/bi";
import { RiTruckLine } from "react-icons/ri";
import { HiOutlineInboxIn } from "react-icons/hi";
import { FaHandsHelping } from "react-icons/fa";

import i18next from "../../i18n";

const items = [
  {
    mainItem: {
      label: i18next.t("sidebar.orders", "Orders"),
      path: "/orders",
      icon: <HiOutlineInboxIn size="21px" />,
    },
    childItems: [
      {
        label: i18next.t("sidebar.all-orders", "All orders"),
        path: "/orders/all",
      },
      {
        label: i18next.t("sidebar.unfulfilled-orders", "Unfulfilled orders"),
        path: "/orders/unfulfilled?fulfillment_status=unfulfilled&not_cancelled=true",
      },
      {
        label: i18next.t(
          "sidebar.partially-fulfilled-orders",
          "Partially fulfilled"
        ),
        path: "/orders/partial?fulfillment_status=partial&not_cancelled=true",
      },
      {
        label: i18next.t("sidebar.fulfilled-orders", "Fulfilled"),
        path: "/orders/fulfilled?fulfillment_status=fulfilled&not_cancelled=true",
      },
    ],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.carriers", "Carriers"),
      path: "/carriers",
      icon: <RiTruckLine size="21px" />,
    },
    childItems: [],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.packages", "Packages"),
      path: "/packages",
      icon: <BiPackage size="21px" />,
    },
    childItems: [],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.locations", "Locations"),
      path: "/locations",
      icon: <BiMap size="21px" />,
    },
    childItems: [],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.shipping-prices", "Shipping prices"),
      path: "/carrier-calculated-shipping",
      icon: <BiPaperPlane size="21px" />,
    },
    childItems: [],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.settings", "Settings"),
      path: "/settings",
      icon: <BiCog size="21px" />,
    },
    childItems: [],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.more-apps"),
      icon: <FaHandsHelping size="21px" />,
      uri: "https://moship.io/es",
      target: "blank",
    },
    childItems: [],
  },
  {
    mainItem: {
      label: i18next.t("sidebar.help", "Help"),
      path: "/help",
      icon: <BiHelpCircle size="21px" />,
    },
    childItems: [],
  },
];

export default items;
