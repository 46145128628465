import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useAxios, useSWR } from "moship";
import * as React from "react";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useRateSubmit } from "../hooks";

interface AdditionalKiloSettingsProps {
  originId: number;
  rateId: number | null;
  onSuccess: (rateId: number) => void;
}

const AdditionalKiloSettings: React.FC<AdditionalKiloSettingsProps> = ({
  originId,
  rateId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();

  const rateSubmit = useRateSubmit();
  const toast = useToast();

  const { data: origin } = useSWR(`/api/v1/origin/${originId}/`);
  const [rateValues, setRateValues] = useState({
    origin: originId,
    internal_name: "",
    name: "",
    description: "",
    type: "additional_kilo",
    price: "",
  });
  const [typeSettings, setTypeSettings] = useState({
    initial_range_price: "",
    additional_range_start: "",
    additional_kilo_price: "",
  });

  const onRateChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRateValues({
      ...rateValues,
      [event.target.name]: event.target.value,
    });
  };

  const onTypeSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeSettings({
      ...typeSettings,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (rateId !== 0) {
      axios
        .get(`/api/v1/origin/${originId}/rates/${rateId}/`)
        .then((response: any) => {
          setRateValues({
            ...rateValues,
            ...response.data,
            type: "additional_kilo",
          });
          setTypeSettings({
            ...typeSettings,
            ...response.data.type_settings,
          });
        });
    }
    return () => {};
  }, [axios, originId, rateId]);

  return (
    <>
      <Box width={"100%"}>
        <form
          id="save-rate"
          onSubmit={(e) => {
            e.preventDefault();
            rateSubmit(originId, rateId, rateValues, typeSettings)
              .then((response) => {
                toast({
                  title: t("Success"),
                  description: t("Rate saved successfully"),
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                onSuccess(response.data.id);
              })
              .catch(() => {
                toast({
                  title: t("Error"),
                  description: t("Something went wrong"),
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              });
          }}
        >
          <VStack>
            <FormControl>
              <FormLabel>{t("Internal Name")}</FormLabel>
              <Input
                value={rateValues.internal_name}
                name="internal_name"
                onChange={onRateChange}
                required
              />
              <FormHelperText>
                {t("This name will be used internally to identify the rate")}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>{t("Name")}</FormLabel>
              <Input
                value={rateValues.name}
                name="name"
                onChange={onRateChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Description")}</FormLabel>
              <Textarea
                value={rateValues.description}
                name="description"
                onChange={onRateChange}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t("Initial range price")}</FormLabel>
              <InputGroup>
                <Input
                  value={typeSettings.initial_range_price}
                  name="initial_range_price"
                  type={"number"}
                  onChange={onTypeSettingsChange}
                  required
                />
                <InputRightAddon>$</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{t("Additional range start")}</FormLabel>
              <InputGroup>
                <Input
                  value={typeSettings.additional_range_start}
                  type={"number"}
                  name="additional_range_start"
                  onChange={onTypeSettingsChange}
                  required
                />
                <InputRightAddon>KG</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{t("Additional kilo price")}</FormLabel>
              <InputGroup>
                <Input
                  value={typeSettings.additional_kilo_price}
                  type={"number"}
                  name="additional_kilo_price"
                  onChange={onTypeSettingsChange}
                  required
                />
                <InputRightAddon>$</InputRightAddon>
              </InputGroup>
            </FormControl>
          </VStack>
        </form>
      </Box>
    </>
  );
};

export default AdditionalKiloSettings;
