import {
  Box,
  Button,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stat,
  StatHelpText,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsCheck } from "react-icons/bs";
import React from "react";

export interface PriceColumnProps {
  id: number;
  title: string;
  description: string;
  price: number;
  disabled: boolean;
  buttonText: string;
  featuresTitle: string;
  features: Array<string>;
  onClick: (id: number) => void;
}

const PriceColumn: React.FC<PriceColumnProps> = ({
  id,
  title,
  description,
  price,
  buttonText,
  featuresTitle,
  features,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();
  const featuresRender = features.map((feature, index) => {
    return (
      <ListItem key={index}>
        <ListIcon as={BsCheck} />
        {feature}
      </ListItem>
    );
  });
  return (
    <>
      <Box
        _first={{ borderLeft: "none" }}
        borderLeft="2px solid"
        borderColor="blackAlpha.300"
      >
        <Box width="100%" borderRadius="4px" padding="2rem 1rem">
          <Heading size="lg" marginBottom="0.5rem">
            {title}
          </Heading>
          <Text color="blackAlpha.600" lineHeight="1rem">
            {description}
          </Text>
          <Stat marginY="1rem" lineHeight="1.8rem">
            <StatNumber fontSize="2rem" fontWeight="bold">
              ${price}
            </StatNumber>
            <StatHelpText paddingLeft="0.3rem">{t("monthly")}</StatHelpText>
          </Stat>
          <Button
            isFullWidth
            colorScheme="teal"
            marginBottom="1.2rem"
            borderRadius="0"
            disabled={disabled}
            onClick={() => {
              onClick(id);
            }}
          >
            {buttonText}
          </Button>
          <Text fontWeight="bold" marginTop="0.5rem" marginBottom="0.5rem">
            {featuresTitle}
          </Text>
          <List spacing={1}>{featuresRender}</List>
        </Box>
      </Box>
    </>
  );
};

export default PriceColumn;
