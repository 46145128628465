import React from "react";

import { Box, Stack, Skeleton } from "@chakra-ui/react";

const LoadingBox: React.FC<{}> = () => {
  return (
    <Box
      padding="1rem 1rem 1rem 1rem"
      marginY="1rem"
      borderRadius="4px"
      border="1px solid #3333334d"
    >
      <Stack>
        <Skeleton height="45px" />
        <Skeleton height="26px" />
        <Skeleton height="26px" />
        <Skeleton height="26px" />
        <Skeleton height="35px" />
      </Stack>
    </Box>
  );
};

export default LoadingBox;
