import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useAxios, useSWR } from "moship";
import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRateSubmit } from "../hooks";

export interface InsuredSettingsType {
  insurance_percentage: number;
  min_order_total: number;
  max_order_total: number;
  product_max_price: number;
  max_shipment_order_value: number;
}

interface InsuredSettingsProps {
  originId: number;
  rateId: number | null;
  onSuccess: (rateId: number) => void;
}

const InsuredSettings: React.FC<InsuredSettingsProps> = ({
  originId,
  rateId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();

  const rateSubmit = useRateSubmit();
  const toast = useToast();

  const { data: origin } = useSWR(`/api/v1/origin/${originId}/`);
  const [rateValues, setRateValues] = useState({
    origin: originId,
    internal_name: "",
    name: "",
    description: "",
    type: "insured",
    price: "",
  });
  const [typeSettings, setTypeSettings] = useState<InsuredSettingsType>({
    insurance_percentage: 0,
    min_order_total: 0,
    max_order_total: 0,
    product_max_price: 0,
    max_shipment_order_value: 0,
  });

  const onRateChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRateValues({
      ...rateValues,
      [event.target.name]: event.target.value,
    });
  };

  const onTypeSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeSettings({
      ...typeSettings,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (rateId !== 0) {
      axios
        .get(`/api/v1/origin/${originId}/rates/${rateId}/`)
        .then((response: any) => {
          setRateValues({
            ...rateValues,
            ...response.data,
            type: "insured",
          });
          setTypeSettings({
            ...typeSettings,
            ...response.data.type_settings,
          });
        });
    }
    return () => {};
  }, [axios, originId, rateId]);

  return (
    <Box width={"100%"}>
      <form
        id="save-rate"
        onSubmit={(e) => {
          e.preventDefault();
          rateSubmit(originId, rateId, rateValues, typeSettings)
            .then((response) => {
              toast({
                title: t("Success"),
                description: t("Rate saved successfully"),
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              onSuccess(response.data.id);
            })
            .catch(() => {
              toast({
                title: t("Error"),
                description: t("Something went wrong"),
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            });
        }}
      >
        <VStack>
          <FormControl>
            <FormLabel>{t("Internal Name")}</FormLabel>
            <Input
              value={rateValues.internal_name}
              name="internal_name"
              onChange={onRateChange}
              required
            />
            <FormHelperText>
              {t("This name will be used internally to identify the rate")}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>{t("Name")}</FormLabel>
            <Input
              value={rateValues.name}
              name="name"
              onChange={onRateChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Description")}</FormLabel>
            <Textarea
              value={rateValues.description}
              name="description"
              onChange={onRateChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Price")}</FormLabel>
            <InputGroup>
              <Input
                value={rateValues.price}
                name="price"
                onChange={onRateChange}
                type={"number"}
                required
              />
              {origin && <InputRightAddon>{origin.currency}</InputRightAddon>}
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Insurance percentage</FormLabel>
            <Input
              type="number"
              name="insurance_percentage"
              value={typeSettings.insurance_percentage}
              onChange={onTypeSettingsChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Min order total</FormLabel>
            <Input
              type="number"
              name="min_order_total"
              value={typeSettings.min_order_total}
              onChange={onTypeSettingsChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Max order total</FormLabel>
            <Input
              type="number"
              name="max_order_total"
              value={typeSettings.max_order_total}
              onChange={onTypeSettingsChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product max price</FormLabel>
            <Input
              type="number"
              name="product_max_price"
              value={typeSettings.product_max_price}
              onChange={onTypeSettingsChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Max shipment order value</FormLabel>
            <Input
              type="number"
              name="max_shipment_order_value"
              value={typeSettings.max_shipment_order_value}
              onChange={onTypeSettingsChange}
              required
            />
          </FormControl>
        </VStack>
      </form>
    </Box>
  );
};

export default InsuredSettings;
