import useSWR from "swr";
import axios from "axios";
import { useParams } from "react-router-dom";

export const indexFetcher = (path: string, userIndex: string) => {
  let headers = {};
  if (userIndex) headers["moship-user-index"] = userIndex;
  return axios.get(path, { headers: headers }).then((res) => res.data);
};

export const useMoshipSWR = (path: string | (() => string)) => {
  let { userIndex } = useParams<{ userIndex: string }>();
  const requestPath = typeof path === "function" ? path() : path;

  const { data, error, isValidating, mutate } = useSWR(
    [requestPath, userIndex],
    indexFetcher,
    {
      revalidateOnFocus: true,
    }
  );

  return {
    data: data,
    isValidating: isValidating,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default useMoshipSWR;
