import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAxios, useSWR } from "moship";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface AddRateModalProps {
  cityId: number;
  originId: string;
  onClose: () => void;
}

const AddRateModal: React.FC<AddRateModalProps> = ({
  cityId,
  onClose,
  originId,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { data: rates } = useSWR(`/api/v1/origin/${originId}/rates/`);
  const { data: city } = useSWR(
    cityId === 0 ? null : `/api/v1/cities/${cityId}/`
  );
  const { data: currentRates, mutate: currentRatesMutate } = useSWR(
    cityId === 0 ? null : `/api/v1/origin/${originId}/cities/${cityId}/rates/`
  );

  if (!rates) return null;
  if (!city) return null;
  if (!currentRates) return null;

  const currentRatesIds = currentRates.map((rate) => rate.id);

  return (
    <>
      <Modal isOpen={cityId !== 0} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            {city && t("add-rate-title", { city: city.name })}
          </ModalHeader>
          <ModalBody>
            <VStack>
              {rates &&
                rates.map((rate) => {
                  console.log(rate);

                  return (
                    <Box key={rate.id} width={"100%"}>
                      <Box
                        justifyContent={"space-between"}
                        display="flex"
                        width={"100%"}
                        alignItems={"center"}
                      >
                        <Box>
                          <Text fontWeight={"500"} lineHeight="1rem">
                            {rate.internal_name}
                          </Text>
                          <Text fontSize={"sm"} lineHeight="1rem">
                            {rate.name}
                          </Text>
                          <Text fontSize={"sm"} lineHeight="1rem">
                            {rate.description}
                          </Text>
                          <Text
                            fontSize={"sm"}
                            lineHeight="1rem"
                            fontWeight={"500"}
                          >
                            {rate.price_is_calculated
                              ? t("calculated", "Calculated")
                              : `$${rate.price}`}
                          </Text>
                        </Box>
                        {!currentRatesIds.includes(rate.id) && (
                          <Button
                            size={"sm"}
                            colorScheme={"teal"}
                            onClick={() => {
                              axios
                                .post(`/api/v1/origin/${originId}/city-rate/`, {
                                  city_id: cityId,
                                  rate_id: rate.id,
                                })
                                .then((response) => {
                                  currentRatesMutate();
                                });
                            }}
                          >
                            {t("Add")}
                          </Button>
                        )}
                        {currentRatesIds.includes(rate.id) && (
                          <Button
                            size={"sm"}
                            onClick={() => {
                              axios
                                .delete(
                                  `/api/v1/origin/${originId}/city-rate/?city_id=${cityId}&rate_id=${rate.id}`
                                )
                                .then((response) => {
                                  currentRatesMutate();
                                });
                            }}
                          >
                            {t("Remove")}
                          </Button>
                        )}
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddRateModal;
