import React from "react";

import { useTranslation } from "react-i18next";
import {
  Text,
  Box,
  Flex,
  Image,
  Heading,
  HStack,
  Button,
  VStack,
} from "@chakra-ui/react";

import envelope from "images/envelope.png";
import box from "images/box.png";

import DeleteButton from "components/DeleteButton";

interface PackagesTableProps {
  packages: Array<any>;
  onEditClick: (packageId) => void;
  onDeleteClick: (packageId) => void;
}

const PackagesTable: React.FC<PackagesTableProps> = ({
  packages,
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  const packagesRender = packages.map((packageItem, index) => {
    const image = packageItem.type === "box" ? box : envelope;
    return (
      <Flex
        key={index}
        borderBottom="1px solid #333"
        padding="1rem 1rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack spacing={5}>
          <Box>
            <Image src={image} />
          </Box>
          <Box>
            <Heading size="md">{packageItem.name}</Heading>

            <Text>
              <strong>{t("Lenght")}: </strong>
              {packageItem.length} {packageItem.unit_of_length}
            </Text>
            <Text>
              <strong>{t("Width")}: </strong>
              {packageItem.width} {packageItem.unit_of_length}
            </Text>
            <Text>
              <strong>{t("Height")}: </strong>
              {packageItem.height} {packageItem.unit_of_length}
            </Text>
            <Text>
              <strong>{t("Weight")}: </strong>
              {packageItem.weight} {packageItem.unit_of_mass}
            </Text>
          </Box>
        </HStack>
        <Box>
          <VStack>
            <Button
              minW="100px"
              onClick={() => {
                onEditClick(packageItem.id);
              }}
            >
              {t("pages.packages.edit", "Edit")}
            </Button>

            <DeleteButton
              minWidth="100px"
              buttonText={t("pages.packages.delete-button", "Delete")}
              alertTitle={t(
                "pages.packages.delete-alert-title",
                "Delete package"
              )}
              alertDescription={t(
                "pages.packages.delete-alert-description",
                "Are you sure? You can't undo this action afterwards."
              )}
              cancelButtonText={t(
                "pages.packages.delete-alert-cancel",
                "Cancel"
              )}
              confirmButtonText={t(
                "pages.packages.delete-alert-delete",
                "Delete"
              )}
              onDelete={() => {
                onDeleteClick(packageItem.id);
              }}
            />
          </VStack>
        </Box>
      </Flex>
    );
  });

  return <Box>{packagesRender}</Box>;
};

export default PackagesTable;
