import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "moship";

import { useTranslation } from "react-i18next";
import Background from "./images/background2.jpg";
import Logo from "./images/Logo.png";
interface Props {}

const Install: React.FC<Props> = (props) => {
  let query = useQuery();
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const shop = query.get("shop");
    if (shop) setValue(shop.replace(".myshopify.com", ""));

    return () => {};
  }, [query]);

  const onSubmit = () => {
    window.location.replace("/?shop=" + value);
  };

  return (
    <Box backgroundImage={`url(${Background})`} backgroundSize="cover">
      <Flex minHeight="100vh" alignItems="center" justifyContent="center">
        <Box
          width="90%"
          maxWidth="500px"
          margin="auto"
          background="#fff"
          padding="3rem"
          borderRadius="4px"
          shadow="md"
          textAlign="center"
        >
          <Center marginBottom="1rem">
            <Image src={Logo} />
          </Center>

          <Text marginBottom="1rem">
            {t("Please enter your Shopify subdomain")}
          </Text>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit();
            }}
          >
            <FormControl marginBottom="1rem">
              <InputGroup>
                <InputLeftAddon>https://</InputLeftAddon>
                <Input
                  type="text"
                  value={value}
                  textAlign="center"
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                />
                <InputRightAddon>.myshopify.com</InputRightAddon>
              </InputGroup>
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button color="primary" type="submit">
                {t("Submit")}
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
};

export default Install;
