import React from "react";

import CoordinadoraColombia from "./CoordinadoraColombia";
import ServientregaColombia from "./ServientregaColombia";

interface CarrierRendererProps {
  carrierId: number | string;
  carriers: Array<{ id: number; provider: string }>;
  destination: {
    city: string;
    state: string;
  };
  onCarrierDataChange: (data: any) => void;
}

const CarrierRenderer: React.FC<CarrierRendererProps> = ({
  carriers,
  carrierId,
  destination,
  onCarrierDataChange,
}) => {
  let carrierProvider = "";

  carriers.map((carrier) => {
    if (carrier.id === carrierId) {
      carrierProvider = carrier.provider;
    }
    return null;
  });

  switch (carrierProvider) {
    case "coordinadora_colombia":
      return (
        <CoordinadoraColombia
          destination={destination}
          onCarrierDataChange={onCarrierDataChange}
        />
      );

    case "servientrega_colombia":
      return (
        <ServientregaColombia
          destination={destination}
          onCarrierDataChange={onCarrierDataChange}
        />
      );

    default:
      return null;
  }
};

export default CarrierRenderer;
