import React, { useEffect, useState } from "react";
import { Divider, Heading } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import DaneCodePicker from "containers/DaneCodePicker";

interface CoordinadoraColombiaProps {
  destination: {
    city: string;
    state: string;
  };
  onCarrierDataChange: (data: any) => void;
}

const CoordinadoraColombia: React.FC<CoordinadoraColombiaProps> = ({
  destination,
  onCarrierDataChange,
}) => {
  const [danecode, setDanecode] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    onCarrierDataChange({ dane_code: danecode });
    return () => {};
  }, [danecode, setDanecode, onCarrierDataChange]);

  return (
    <>
      <Heading size="md" marginBottom="0.5rem">
        {t([
          "containers.create-shipment.carrier-renderer.verify-destination-city",
          "Verify destination city",
        ])}
      </Heading>
      <DaneCodePicker destination={destination} onChange={setDanecode} />
      <Divider marginY="1rem" />
    </>
  );
};

export default CoordinadoraColombia;
