import React from "react";
import { Box, Heading, Text } from "@chakra-ui/layout";

interface FormGroupProps {
  title: string;
  description?: string;
  children?: any;
}

const FormGroup: React.FC<FormGroupProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <>
      <Box paddingY="10px">
        <Heading as="h3" marginBottom="0.5rem" size="md">
          {title}
        </Heading>
        {description && <Text marginBottom="1rem">{description}</Text>}
        <Box>{children}</Box>
      </Box>
    </>
  );
};

export default FormGroup;
