import { Alert, AlertTitle, Button, Textarea } from "@chakra-ui/react";
import { useSWR } from "moship";
import * as React from "react";
import { useState, useEffect } from "react";
import { MdChevronLeft } from "react-icons/md";
import { useTranslation } from "react-i18next";

interface CityListProps {
  rateId: number;
  originId: string;
  onBack?: () => void;
}

const CityList: React.FC<CityListProps> = ({ rateId, originId, onBack }) => {
  const { t } = useTranslation();
  const { data: places } = useSWR(
    `/api/v1/origin/${originId}/rates/${rateId}/places/`
  );

  return (
    <>
      {onBack && (
        <Button
          leftIcon={<MdChevronLeft />}
          onClick={onBack}
          marginBottom={"1rem"}
        >
          {t("back")}
        </Button>
      )}
      {places?.length === 0 && (
        <Alert status="warning">
          <AlertTitle>
            {t("No cities have been added to this rate yet")}
          </AlertTitle>
        </Alert>
      )}
      {places?.length > 0 && (
        <Textarea
          height={"70vh"}
          readOnly
          value={places
            .map((place: any, index: number) => {
              const line = `${place.city__name},${place.city__state__name}`;
              if (index < places.length - 1) {
                return `${line}\n`;
              }
              return line;
            })
            .join("")}
        />
      )}
    </>
  );
};

export default CityList;
