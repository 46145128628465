import { Box, Button, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

import GoBack from "./Goback";

interface ContainerProps {
  title: string;
  children?: any;
  onGoBack?: () => void;
  action?: {
    text: string;
    onClick: () => void;
  };
  description?: any;
}

const MoshipContainer: React.FC<ContainerProps> = ({
  title,
  children,
  onGoBack,
  action,
  description,
}) => {
  return (
    <Container maxW="container.xl">
      <Box paddingX="1.2rem">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <GoBack onClick={onGoBack} />
            <Heading as="h1" size="lg" marginY="1.2rem">
              {title}
            </Heading>
          </Flex>
          {action && (
            <Box>
              <Button colorScheme="teal" onClick={action.onClick}>
                {action.text}
              </Button>
            </Box>
          )}
        </Flex>
        {description && <Text marginBottom="2rem">{description}</Text>}
        {children}
      </Box>
    </Container>
  );
};

export default MoshipContainer;
