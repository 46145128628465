import React from "react";

import { Box, Heading, SimpleGrid, Tag, Text } from "@chakra-ui/react";
import FulfillmentTag from "components/FulfillmentTag";

import { useTranslation } from "react-i18next";

interface TopOrderInfoProps {
  orderName: string;
  fulfillmentStatus: string;
  financialStatus: string;
  paymentGateways: Array<string>;
}

const TopOrderInfo: React.FC<TopOrderInfoProps> = ({
  orderName,
  fulfillmentStatus,
  financialStatus,
  paymentGateways,
}) => {
  const { t } = useTranslation();
  const paymentGatewaysRender = paymentGateways.map((paymentGateway, index) => {
    return <Tag key={index}>{paymentGateway}</Tag>;
  });

  return (
    <Box
      border="1px solid"
      borderColor="blackAlpha.300"
      borderRadius={4}
      padding={3}
      marginBottom={3}
    >
      <SimpleGrid columns={4} spacing={3}>
        <Box borderRight="1px solid" borderColor="blackAlpha.300">
          <Heading size="sm" marginBottom="0.1rem">
            {t(["containers.create-shipment.top-order-info.order", "Order"])}
          </Heading>
          <Text>{orderName}</Text>
        </Box>
        <Box borderRight="1px solid" borderColor="blackAlpha.300">
          <Heading size="sm" marginBottom="0.1rem">
            {t([
              "containers.create-shipment.top-order-info.fulfillment-status",
              "Fulfillment status",
            ])}
          </Heading>
          <FulfillmentTag state={fulfillmentStatus} />
        </Box>
        <Box borderRight="1px solid" borderColor="blackAlpha.300">
          <Heading size="sm" marginBottom="0.1rem">
            {t([
              "containers.create-shipment.top-order-info.financial-status",
              "Financial status",
            ])}
          </Heading>
          <Text>
            {t([
              `shopify.financial-status.${financialStatus}`,
              financialStatus,
            ])}
          </Text>
        </Box>
        <Box>
          <Heading size="sm" marginBottom="0.1rem">
            {t([
              "containers.create-shipment.top-order-info.payment-gateways",
              "Payment gateways",
            ])}
          </Heading>
          <Box>{paymentGatewaysRender}</Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default TopOrderInfo;
