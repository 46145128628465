import React, { useState } from "react";
import { useSWR, useAxios } from "moship";
import { useTranslation } from "react-i18next";

import Dashboard from "containers/Dashboard";
import EmptyState from "components/EmptyState";
import Container from "components/Container";

import PackagesTable from "./PackagesTable";
import PackageDrawer from "./PackageDrawer";

interface PackagesProps {}

const Packages: React.FC<PackagesProps> = () => {
  const axios = useAxios();
  const { t } = useTranslation();
  const {
    data: packages,
    isLoading,
    error,
    mutate: packagesMutate,
  } = useSWR("/api/v1/packages/");
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [packageId, setPackageId] = useState(null);

  if (isLoading) return null;
  if (error) return null;

  return (
    <Dashboard>
      <Container
        title={t("pages.packages.title", "Packages")}
        description={t("pages.packages.description", "")}
        action={{
          text: t("pages.packages.add-package", "Add package"),
          onClick: () => {
            setIsDrawerOpened(true);
          },
        }}
      >
        {packages.length !== 0 && (
          <PackagesTable
            packages={packages}
            onEditClick={(packageId) => {
              setIsDrawerOpened(true);
              setPackageId(packageId);
            }}
            onDeleteClick={(packageId) => {
              axios
                .delete(`/api/v1/packages/${packageId}/`)
                .then((response) => {
                  packagesMutate();
                });
            }}
          />
        )}
        {packages.length === 0 && (
          <EmptyState
            title={t(
              "pages.packages.empty-state-title",
              "Add packages for your shipments"
            )}
            description={t(
              "pages.packages.empty-state-description",
              "Set packages, and pick them at the moment of shipment creation"
            )}
            primaryActionText={t("pages.packages.add-package", "Add package")}
            onPrimaryAction={() => {
              setIsDrawerOpened(true);
            }}
          />
        )}
        <PackageDrawer
          packageId={packageId}
          isOpen={isDrawerOpened}
          onClose={() => {
            setIsDrawerOpened(false);
            setPackageId(null);
            packagesMutate();
          }}
        />
      </Container>
    </Dashboard>
  );
};

export default Packages;
