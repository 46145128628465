import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSWR, useAxios } from "moship";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";

import DeleteButton from "components/DeleteButton";
import ShipmentLabels from "containers/ShipmentLabels";

interface FulfillmentProps {
  fulfillmentId: number;
  onFulfillmentCancelled: () => void;
}

const Fulfillment: React.FC<FulfillmentProps> = ({
  fulfillmentId,
  onFulfillmentCancelled,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [cancellingFultillment, setCancellingFultillment] = useState<
    null | "loading" | "success"
  >(null);
  const {
    data: fulfillment,
    isLoading,
    error,
    mutate,
  } = useSWR(`/api/v1/fulfillments/${fulfillmentId}/`);

  if (isLoading) return <h1>loading</h1>;
  if (error) return null;

  const cancelFulfillment = () => {
    setCancellingFultillment("loading");
    axios.delete(`/api/v1/fulfillments/${fulfillment.id}/`).then((response) => {
      setCancellingFultillment("success");
      mutate();
      onFulfillmentCancelled();
    });
  };
  const provider = fulfillment.shipment.carrier.provider;

  return (
    <Box>
      <Text>
        <strong>
          {t("containers.create-shipment.fulfillment.carrier", "Carrier")}:{" "}
        </strong>
        {t(`providers.${provider}`)}
      </Text>
      <Text>
        <strong>
          {t(
            "containers.create-shipment.fulfillment.tracking-code",
            "Tracking code"
          )}
          :{" "}
        </strong>
        {fulfillment.shipment.tracking_code}
      </Text>
      <Divider my={5} />
      {fulfillment.status === "cancelled" && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>
            {t(
              "containers.create-shipment.fulfillment.cancelled",
              "This fulfillment is cancelled and cannot be modified"
            )}
          </AlertTitle>
        </Alert>
      )}
      {fulfillment.shipment && fulfillment.status !== "cancelled" && (
        <ShipmentLabels shipmentId={fulfillment.shipment.id} />
      )}
      {fulfillment.status === "success" && (
        <Flex justifyContent="flex-end" marginY="2rem">
          <DeleteButton
            buttonText={t(
              "containers.create-shipment.fulfillment.cancel-fulfillment",
              "Cancel fullfillment"
            )}
            alertTitle={t(
              "containers.create-shipment.fulfillment.cancel-fulfillment",
              "Cancel fullfillment"
            )}
            alertDescription={t(
              "containers.create-shipment.fulfillment.confirmation",
              "Are you sure? You can't undo this action afterwards."
            )}
            cancelButtonText={t(
              "containers.create-shipment.fulfillment.leave",
              "leave"
            )}
            confirmButtonText={t(
              "containers.create-shipment.fulfillment.cancel-fulfillment",
              "Cancel Fulfillment"
            )}
            onDelete={cancelFulfillment}
            deletingStatus={cancellingFultillment}
            onStatusReset={setCancellingFultillment}
          />
        </Flex>
      )}
    </Box>
  );
};

export default Fulfillment;
