import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Switch,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { useSWR, useAxios } from "moship";
import * as React from "react";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useRateSubmit } from "../hooks";

interface DefaultSettingsProps {
  rateId: number | null;
  originId: number;
  onSuccess: (rateId: number) => void;
}

const DefaultSettings: React.FC<DefaultSettingsProps> = ({
  rateId,
  originId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { data: origin } = useSWR(`/api/v1/origin/${originId}/`);
  const rateSubmit = useRateSubmit();
  const toast = useToast();
  const axios = useAxios();
  const [values, setValues] = useState({
    origin: originId,
    name: "",
    internal_name: "",
    description: "",
    price: "",
    has_conditions: false,
    min_weight: 0,
    max_weight: 0,
    has_free_shipping_threshold: false,
    free_shipping_threshold: "0.00",
    min_cart_total: "0.00",
  });

  useEffect(() => {
    if (rateId !== 0) {
      axios
        .get(`/api/v1/origin/${originId}/rates/${rateId}/`)
        .then((response) => {
          setValues({
            ...values,
            ...response.data,
          });
        });
    }
    return () => {};
  }, [axios, originId, rateId]);

  const onChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  return (
    <Box w="100">
      <form
        id="save-rate"
        onSubmit={(e) => {
          e.preventDefault();
          rateSubmit(originId, rateId, values, {})
            .then((response) => {
              toast({
                title: t("Success"),
                description: t("Rate saved successfully"),
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              onSuccess(response.data.id);
            })
            .catch(() => {
              toast({
                title: t("Error"),
                description: t("Something went wrong"),
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            });
        }}
      >
        <VStack w="100">
          <FormControl>
            <FormLabel>{t("Internal Name")}</FormLabel>
            <Input
              value={values.internal_name}
              onChange={onChange("internal_name")}
              required
            />
            <FormHelperText>
              {t("This name will be used internally to identify the rate")}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>{t("Name")}</FormLabel>
            <Input value={values.name} onChange={onChange("name")} required />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Description")}</FormLabel>
            <Textarea
              value={values.description}
              onChange={onChange("description")}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Price")}</FormLabel>
            <InputGroup>
              <Input
                value={values.price}
                onChange={onChange("price")}
                type={"number"}
                required
              />
              {origin && <InputRightAddon>{origin.currency}</InputRightAddon>}
            </InputGroup>
          </FormControl>
          <br />
          <FormControl display={"flex"} alignItems="center">
            <Switch
              id="rateHasContitions"
              isChecked={values.has_conditions}
              onChange={(e) => {
                setValues({
                  ...values,
                  has_conditions: !values.has_conditions,
                });
              }}
            />
            <FormLabel marginLeft={"0.5rem"} mb="0" htmlFor="rateHasContitions">
              {t("Has conditions")}
            </FormLabel>
          </FormControl>

          {values.has_conditions && (
            <>
              <SimpleGrid columns={2} spacing={2} width={"100%"}>
                <FormControl>
                  <FormLabel>{t("Min weight")}</FormLabel>
                  <InputGroup>
                    <Input
                      value={values.min_weight}
                      onChange={onChange("min_weight")}
                      type={"number"}
                    />
                    <InputRightAddon>KG</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Max weight")}</FormLabel>
                  <InputGroup>
                    <Input
                      value={values.max_weight}
                      onChange={onChange("max_weight")}
                      type={"number"}
                    />
                    <InputRightAddon>KG</InputRightAddon>
                  </InputGroup>
                </FormControl>
              </SimpleGrid>
              <FormControl>
                <FormLabel>{t("Min order value")}</FormLabel>
                <InputGroup>
                  <Input
                    value={values.min_cart_total}
                    onChange={onChange("min_cart_total")}
                    type={"number"}
                  />
                  {origin && (
                    <InputRightAddon>{origin.currency}</InputRightAddon>
                  )}
                </InputGroup>
              </FormControl>
            </>
          )}
          <Box width={"100%"} py={"1rem"}>
            <Divider />
          </Box>
          <FormControl display={"flex"} alignItems="center">
            <Switch
              id="rateHasContitions"
              isChecked={values.has_free_shipping_threshold}
              onChange={(e) => {
                setValues({
                  ...values,
                  has_free_shipping_threshold:
                    !values.has_free_shipping_threshold,
                });
              }}
            />
            <FormLabel marginLeft={"0.5rem"} mb="0" htmlFor="rateHasContitions">
              {t("Is free for some orders?")}
            </FormLabel>
          </FormControl>
          {values.has_free_shipping_threshold && (
            <FormControl>
              <FormLabel>{t("Min order value")}</FormLabel>
              <InputGroup>
                <Input
                  value={values.free_shipping_threshold}
                  onChange={onChange("free_shipping_threshold")}
                  type={"number"}
                />
                {origin && <InputRightAddon>{origin.currency}</InputRightAddon>}
              </InputGroup>
            </FormControl>
          )}
        </VStack>
      </form>
    </Box>
  );
};

export default DefaultSettings;
