import React, { useState, useEffect } from "react";

import { useSWR, useAxios, usePushPath } from "moship";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
  Divider,
  Select,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import FormGroup from "components/FormGroup";
import SaveChanges from "components/SaveChanges";
import ListTable from "components/ListTable";
import CarrierLocations from "containers/CarrierLocations";

interface CarrierSettingsProps {
  carrierId: string | number;
  carrierSettings: Array<{
    sectionName: string;
    sectionDescription: string;
    settings: Array<{
      fieldName: string;
      fieldLabel: string;
      fieldType: string;
      fieldOptions?: Array<any>;
      inputLeftAddon?: any;
      inputRightAddon?: any;
      formHelperText?: string;
    }>;
  }>;
  orderRequirements: Array<string>;
}

export const CarrierSettings: React.FC<CarrierSettingsProps> = ({
  carrierId,
  carrierSettings,
  orderRequirements,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();
  const {
    data: carrier,
    isLoading,
    error,
  } = useSWR(`/api/v1/carriers/${carrierId}/`);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeLocations, setActiveLocations] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const pushPath = usePushPath();

  let valuesNames = {};

  for (let index = 0; index < carrierSettings.length; index++) {
    const section = carrierSettings[index];
    for (let index = 0; index < section.settings.length; index++) {
      const setting = section.settings[index];
      valuesNames[setting.fieldName] = "hello";
    }
  }

  const [values, setValues] = useState(valuesNames);

  useEffect(() => {
    if (!error && !isLoading) {
      setName(carrier.name);
      setValues(carrier.settings);
      const locations = carrier.active_locations.map((location) => {
        return location.id;
      });
      setActiveLocations(locations);
    }

    return () => {};
  }, [carrier, error, isLoading]);

  if (isLoading) return null;
  if (error) return null;

  const onChange = (name) => (event) => {
    setFormChanged(true);
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const onCarrierLocationsChange = (locations) => {
    setFormChanged(true);
    setActiveLocations(locations);
  };

  const onsubmit = () => {
    setLoading(true);
    axios
      .put(`/api/v1/carriers/${carrierId}/`, {
        name: name,
        active_locations: activeLocations,
        settings: values,
      })
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
          setFormChanged(false);
          toast({
            title: t("new settings saved"),
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    axios.delete(`/api/v1/carriers/${carrierId}`).then((response) => {
      pushPath("/carriers");
    });
  };

  const sections = carrierSettings.map((section, index) => {
    const settings = section.settings.map((setting, index) => {
      const onItemChange = onChange(setting.fieldName);
      const value = values[setting.fieldName];
      return (
        <FormControl key={index}>
          <FormLabel>{setting.fieldLabel}</FormLabel>
          <InputGroup>
            {setting.inputLeftAddon && (
              <InputLeftAddon>{setting.inputLeftAddon}</InputLeftAddon>
            )}
            {setting.fieldType === "text" && (
              <Input onChange={onItemChange} value={value} required />
            )}
            {setting.fieldType === "password" && (
              <Input
                onChange={onItemChange}
                value={value}
                type="password"
                required
              />
            )}
            {setting.fieldType === "number" && (
              <Input
                onChange={onItemChange}
                value={value}
                type="number"
                required
              />
            )}
            {setting.fieldType === "select" && (
              <Select onChange={onItemChange} value={value} required>
                <option value=""></option>
                {setting.fieldOptions && setting.fieldOptions}
              </Select>
            )}
            {setting.inputRightAddon && (
              <InputRightAddon>{setting.inputRightAddon}</InputRightAddon>
            )}
          </InputGroup>
          {setting.formHelperText && (
            <FormHelperText>{setting.formHelperText}</FormHelperText>
          )}
        </FormControl>
      );
    });

    return (
      <Box key={index}>
        <Divider my="2rem" />
        <FormGroup
          title={section.sectionName}
          description={section.sectionDescription}
        >
          <Stack spacing={3}>{settings}</Stack>
        </FormGroup>
      </Box>
    );
  });

  return (
    <Box position="relative" mb="7rem">
      <Box marginBottom="2rem">
        <ListTable
          title={t(
            "containers.carrier-settings.requirements-title",
            "Order requirements for this carrier"
          )}
          items={orderRequirements}
        />
      </Box>
      <FormGroup
        title={t("containers.carrier-settings.location", "Location")}
        description={t("containers.carrier-settings.location-description", "")}
      >
        <CarrierLocations
          onChange={onCarrierLocationsChange}
          activeLocations={activeLocations}
        />
      </FormGroup>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onsubmit();
        }}
      >
        <FormControl marginBottom="1rem">
          <FormLabel>
            {t("containers.carrier-settings.custom-name", "Custom name")}
          </FormLabel>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setFormChanged(true);
            }}
            required
          />
        </FormControl>
        {sections}
        <SaveChanges
          isLoading={loading}
          formChanged={formChanged}
          onDelete={onDelete}
          deleteAlertTitle={t("Delete carrier")}
          deleteAlertDescription={t(
            "Are you sure? You can't undo this action afterwards."
          )}
        />
      </form>
    </Box>
  );
};

export default CarrierSettings;
